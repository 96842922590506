import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { localStorageService } from '../localstorage.service';
import { MainService } from '../main.service';
import { countryType, SharedService, timezoneType } from '../shared.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  menuList: any = [];
  timezoneList: any = [];
  selectedTimezone: any;

  countryList: any = [];
  selectedCountry: any;

  isDummy = true;
  constructor(private _router: Router,private modalService: BsModalService,private _local : localStorageService, private _main: MainService, public _shared: SharedService, private route: ActivatedRoute) {}

  ngOnInit() {
    this._main.menu$.subscribe(menus => {
      this.menuList = menus;
      let all_menu: any = [];
      this.menuList.forEach((item: any) => {
        // if (item['only_path'] === '1') {
        //   main_menu.splice(index, 1);
        // }
        let submenu: any = [];
        if (item['submenu'] && item['submenu'].length > 0) {
          item['submenu'].forEach((sub: any) => {
            if (sub['only_path'] !== '1') {
              if (!submenu) {
                submenu = [];
              }
              submenu.push(sub);
            }
          });
        }
        item.submenu = submenu;
        if (item['only_path'] !== '1') {
          all_menu.push(item);
        }
      });
  
      all_menu.forEach((item: any) => {
        if (item.submenu) {
          item.submenu = item.submenu.sort((a: any, b: any) => {
            return a.in_order - b.in_order;
          });
          if (item.submenu.length === 0) {
            item.submenu = null;
          }
        }
      });
      this.menuList = all_menu;
    })

    const queryParams = { ...this.route.snapshot.queryParams };
    this.isDummy = queryParams && queryParams['is_dummy'] == 1 ? true : false;
    console.log('this.isDummythis.isDummy', this.isDummy);
    this._main.country_data$.subscribe(counList => {
      this.countryList = counList;
      let item = counList.find((country: countryType) => country.country_id === (queryParams && Number(queryParams['country_id'])));
      item = item && item.country_id ? item : counList[0];
      if (item && item.country_id) {
        this.selectedCountry = item;
        this._shared.mask = item.country_phone_format;
      }
      this.updateQueryParams();
    })

    this._main.timezoneList$.subscribe(tZList => {
      if (tZList && tZList.length) {
        // static US country set
        this.timezoneList = tZList;
        // this.timezoneList = tZList.filter((item: any) => item.country_id === 1);
        let item = tZList.find((timezone: timezoneType) => timezone['val'] === (queryParams && queryParams['timezone']));
        item = item && item.tz_id ? item : tZList[0];
        this.selectedTimezone = item;
        this._shared.timezoneName$.next(item['name']);
        this.updateQueryParams();
        // this._shared.timezoneName$.next(timeZ['name']);
        // this._shared.zone$.next(timeZ['zone']);
      }
   })
    // this._main.getTimezoneCountry().subscribe(([tZList, counList]) => {
    //   if (tZList && tZList.length){
    //     this.timezoneList = tZList;
    //     let item = tZList.find((timezone: timezoneType) => timezone['val'] === (queryParams && queryParams['timezone']));
    //     item = item && item.tz_id ? item : tZList[0];
    //     this.selectedTimezone = item;
    //     this._shared.timezoneName$.next(item['name']);
    //     // this._shared.zone$.next(timeZ['zone']);
    //   }

    //   this.countryList = counList;
    //   let item = counList.find((country: countryType) => country.country_id === (queryParams && Number(queryParams['country_id'])));
    //   item = item && item.cocu_id ? item : counList[0];
    //   if (item && item.cocu_id) {
    //     this.selectedCountry = item;
    //     this._shared.mask = item.country_phone_format;
    //   }
    //   this.updateQueryParams();
    // })
    
  }

  toggleChange() {
    this.isDummy = !this.isDummy;
    this.updateQueryParams();
  }

  changeCountry(country: any) {
    if (country){
      this.selectedCountry = country;
      this._shared.mask = country.country_phone_format;
      this.updateQueryParams();
    }
  }

  changeTimezone(timezone: any) {
    this.selectedTimezone = timezone;
    this._shared.timezone = timezone['val'];
    this._shared.timezoneName = timezone['name'];
    this._shared.timezoneName$.next(timezone['name']);
    this.updateQueryParams();
  }
  
  toggleMenu(selectedMenu: any) {
    if (selectedMenu.submenu) {
      selectedMenu.isOpen = !selectedMenu.isOpen;
    } else {
      selectedMenu.isOpen = true;
      this._router.navigate([selectedMenu.path]);
    }
    // this.menuList.forEach((menu: any) => {
    //   if (menu === selectedMenu) {
    //     if (menu.submenu) {
    //       menu.isOpen = !menu.isOpen;
    //     } else {
    //       this._router.navigate([menu.path]);
    //     }
    //   } else {
    //     menu.isOpen = false;
    //   }
    // });
  }

  openNavMenu() {
    const sidenav = document.getElementById('mySidenav');
    if (sidenav) {
      sidenav.style.transform = 'translate(0%)';
    }
  }

  closeNavMenu() {
    const sidenav = document.getElementById('mySidenav');
    if (sidenav) {
      sidenav.style.transform = 'translate(-100%)';
    }
  }
 
  showLogoutModal() {
    const modelRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        extra_data: {
          label: 'Are you sure you want to Logout ?',
          hideNotes: true
        }
      },
      class : 'modal-dialog-centered',
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this._local.customClearAllItems()
        this._router.navigate(['login']);
        // let data = {
        //   admin_id: user.admin_id
        // };
        // this._api.api(data, 'send_credentials_to_admin').subscribe((data: any) => { 
        //   console.log(data); 
        // });
      }
    })
  }

  // Method to add or update the query parameters
  updateQueryParams(): void {
    this._local.customSetItem('is_dummy', this.isDummy ? '1' : '0');
    this._local.customSetItem('country_id', (this.selectedCountry && this.selectedCountry.country_id) || 1);
    this._local.customSetItem('timezone', (this.selectedTimezone && this.selectedTimezone['val']) || '+530');
    // Get the existing query params from the current route
    const queryParams = { ...this.route.snapshot.queryParams };

    // Update the dummy and country_selected parameters
    queryParams['is_dummy'] = this.isDummy ? 1 : 0;
    queryParams['country_id'] = (this.selectedCountry && this.selectedCountry.country_id) || '1';
    queryParams['timezone'] = (this.selectedTimezone && this.selectedTimezone['val']) || '+530';

    // Use router to update the query params without reloading the page
    this._router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',  // Merge with existing query params
      replaceUrl: true               // Replace URL in history without adding a new entry
    });
  }
}
