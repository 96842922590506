// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    baseUrl: 'https://testadmin.financemutual.com/api/admin/',
    // baseUrl: 'http://localhost:3000/admin/',
    socketBaseUrl: 'https://testadmin.financemutual.com',
    app_version: 130,
    app_type: 1,
    device_type: 0,
    device_token: 'Website',
    env: 'TEST',
    url: 'https://test.credee.com/admin',
    // url: 'http://localhost:4200/',
    download_receipt_link: 'https://testfiles.financemutual.com/tr/downloadReceipt.html?id=',
    // business_stripe_url: 'https://dashboard.stripe.com/test/connect/accounts/'

};
