import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './public/login/login.component';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { RouterModule } from '@angular/router';
import { PaginationModule, PaginationConfig } from 'ngx-bootstrap/pagination';
import { INgxSelectOptions, NgxSelectModule } from 'ngx-select-ex';
import { ContractsModule } from './contracts/contracts.module';
import { ProposalModule } from './proposal/proposal.module';
import { NewPasswordComponent } from './public/new-password/new-password.component';
import { LeadsModule } from './leads/leads.module';
import { DebtRecoveryModule } from './debt-recovery/debt-recovery.module';
import { CustomersModule } from './customers/customers.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';

const CustomSelectOptions: INgxSelectOptions = {
  // Check the interface for more options
  optionValueField: 'id',
  optionTextField: 'name',
};

@NgModule({
  declarations: [DashboardComponent, LoginComponent, NewPasswordComponent],
  imports: [
    CommonModule,
    CustomersModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    RouterModule,
    PaginationModule,
    NgxSelectModule.forRoot(CustomSelectOptions),
    ContractsModule,
    ProposalModule,
    LeadsModule,
    DebtRecoveryModule,
    AccordionModule
  ],
  providers: [PaginationConfig],
})
export class PagesModule {}
