import { Injectable, ModuleWithProviders, NgModule } from '@angular/core';
import { LoaderComponent } from './loader/loader.component';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { localStorageService } from './localstorage.service';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxSelectModule, INgxSelectOptions } from 'ngx-select-ex';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaymentTableComponent } from './payment-table/payment-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FiltersComponent } from './filters/filters.component';
import { AddNotesComponent } from './add-notes/add-notes.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { EditContractModalComponent } from './edit-contract-modal/edit-contract-modal.component';
import { GoogleZipComponent } from './google-zip/google-zip.component';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { Loader } from '@googlemaps/js-api-loader';
import { ViewNotesComponent } from './view-notes/view-notes.component';
import { GenerateStripeElementComponent } from './generate-stripe-element/generate-stripe-element.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CommonNotesListingComponent } from './common-notes-listing/common-notes-listing.component';
import { NotesListComponent } from './hidden-notes/notes-list/notes-list.component';
import { AddNoteComponent } from './hidden-notes/add-note/add-note.component';
import { SpecialNoteComponent } from './special-note/special-note.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AssignmentTabsComponent } from './assignment-tabs/assignment-tabs.component';
import { EditorCodemirrorComponent } from './editor-codemirror/editor-codemirror.component';
import { NgxEditorModule } from 'ngx-editor';
import { HttpClientModule } from '@angular/common/http';
import { UpdateCustomerModalComponent } from './customer-update/customer-update.component';
import { CalculationDetailsComponent } from './calculation-details/calculation-details.component';
import { ExportCsrNotesComponent } from './export-csr-notes/export-csr-notes.component';
import { ProfileImageModalComponent } from './profile-image-modal/profile-image-modal.component';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { AddEditTrainingComponent } from './add-edit-training/add-edit-training.component';

const CustomSelectOptions: INgxSelectOptions = {
  // Check the interface for more options
  optionValueField: 'id',
  optionTextField: 'name',
};

@Injectable({
  providedIn: 'root',
})
@NgModule({
  declarations: [
    LoaderComponent,
    PaymentTableComponent,
    FiltersComponent,
    AddNotesComponent,
    EditContractModalComponent,
    GoogleZipComponent,
    ViewNotesComponent,
    GenerateStripeElementComponent,
    CommonNotesListingComponent,
    NotesListComponent,
    AddNoteComponent,
    SpecialNoteComponent,
    AssignmentTabsComponent,
    EditorCodemirrorComponent,
    UpdateCustomerModalComponent,
    CalculationDetailsComponent,
    ExportCsrNotesComponent,
    ProfileImageModalComponent,
    AddEditTrainingComponent
  ],
  imports: [
    CommonModule,
    NgxMaskDirective,
    RouterModule,
    FormsModule,
    ModalModule.forRoot(),
    NgxSelectModule.forRoot(CustomSelectOptions),
    BsDatepickerModule.forRoot(),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    ReactiveFormsModule,
    ProgressbarModule.forRoot(),
    NgxGpAutocompleteModule,
    BsDropdownModule,
    NgxMaskDirective,
    NgxMaskPipe,
    PaginationModule,
    HttpClientModule,
    NgxEditorModule.forRoot({
      locals: {
        // menu
        bold: 'Bold',
        italic: 'Italic',
        code: 'Code',
        underline: 'Underline',
        strike: 'Strike',
        blockquote: 'Blockquote',
        bullet_list: 'Bullet List',
        ordered_list: 'Ordered List',
        heading: 'Heading',
        h1: 'Header 1',
        h2: 'Header 2',
        h3: 'Header 3',
        h4: 'Header 4',
        h5: 'Header 5',
        h6: 'Header 6',
        align_left: 'Left Align',
        align_center: 'Center Align',
        align_right: 'Right Align',
        align_justify: 'Justify',
        text_color: 'Text Color',
        background_color: 'Background Color',
    
        // pupups, forms, others...
        url: 'URL',
        text: 'Text',
        openInNewTab: 'Open in new tab',
        insert: 'Insert',
        altText: 'Alt Text',
        title: 'Title',
        remove: 'Remove',
      },
    }),
    ImageCropperComponent,
    CodemirrorModule
  ],
  providers: [
    localStorageService,
    DatePipe,
    DecimalPipe,
    provideNgxMask(),
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: window.location.href.includes('.financemutual.com')
          ? 'AIzaSyAa0GBtCtHP69nQ3EhYc69G2xyXXXAQCYE'
          : 'AIzaSyAB3iHn_Dc0nEjS7q53tfCBWVm1_Dj1fdI',
        libraries: ['places'],
      }),
    },
  ],
  exports: [
    LoaderComponent,
    NgxSelectModule,
    BsDatepickerModule,
    PopoverModule,
    TooltipModule,
    PaymentTableComponent,
    NgxGpAutocompleteModule,
    FiltersComponent,
    AddNotesComponent,
    EditContractModalComponent,
    GoogleZipComponent,
    ViewNotesComponent,
    GenerateStripeElementComponent,
    BsDropdownModule,
    CommonNotesListingComponent,
    NotesListComponent,
    AddNoteComponent,
    SpecialNoteComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    PaginationModule,
    AssignmentTabsComponent,
    NgxEditorModule,
    EditorCodemirrorComponent,
    CalculationDetailsComponent,
    ExportCsrNotesComponent,
    ProfileImageModalComponent,
    AddEditTrainingComponent
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
