import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from 'src/app/components/shared/api.service';
import { FiltersService } from 'src/app/components/shared/filters/filters.service';
import { Filters } from 'src/app/components/shared/interface.component';
import { localStorageService } from 'src/app/components/shared/localstorage.service';
import { MainService } from 'src/app/components/shared/main.service';
import { SharedService } from 'src/app/components/shared/shared.service';
import { ContractModalComponent } from '../contract-modal/contract-modal.component';
import { ContractService } from '../contracts.service';

@Component({
  selector: 'app-disputed',
  templateUrl: './disputed.component.html',
  styleUrl: './disputed.component.scss'
})
export class DisputedComponent {
  reqData: any = {
    type: 0,
    filter: 0,
    flag: 0,
    contract_type: 0
  };
  search: any = '';
  charge_filter = 'All';
  contracts: any;
  Business_special_notes: any;
  Business_dnd: any;
  bankrupt_stop_payment: any;
  Customer_dnd: any;
  moveRemoveUnpaidText: any;
  contract_special_notes: any;

  loader: boolean = true;
  Business_stripe_balance: any = 0;
  resData: any = {
    pageNumber: 1
  };
  currencyData = {
    bank_support_type: ['us_bank_account'],
    currency_code: 1,
    currency_id: "US Dollar",
    display_logo: "$"
  }
  countryData = {
    country_code: "US",
    country_date_format: "MM/dd/yyyy",
    country_id: 1,
    country_name: "United States",
    country_phone_format: "###-###-####",
    mobile_code: "+1"
  }
  dispute_filter = 'All';
  patient_filter_date: any = null;
  is_dummy: any = 0;
  subscription: any;
  resetBtn = false;
  admin_name: any;
  timezone: any = '';
  timezoneName: any = '';
  hadSearched: boolean = false;
  offset: any;
  selectedDispute: any;
  manual_amount: any;
  skip_email: any;
  is_remove_from_unpaid: any;
  note: any;
  onOffCustomAmount: any = 0;
  invalidManualAmount: any = 0;
  maxManualAmount: any = 0;
  timezone_subscription: any;
  show_filters: boolean = false;
  public filters: Array<Filters> = [];
  filter_added: boolean = false;
  country_id = 0;
  country_subscription: any;
  currency_logo = '$';
  constructor(
    public mainService: MainService,

    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private _router: Router,
    public _contract: ContractService,
    private modalService: BsModalService,
    public changeDetect: ChangeDetectorRef,
    private _local: localStorageService,
    private _api: ApiService,
    private _shared: SharedService,
    private filterService: FiltersService

  ) { }

  ngOnInit() {
    // this.subscription = this._shared.updateDummyLive().subscribe(([isDummy, timezone, selected_country]) => {
    //   if (isDummy >= 0 || (selected_country && selected_country.length > 0)) {
    //     this.is_dummy = isDummy;
    //     this.country_id = selected_country;
    //     this.currency_logo = '$';
    //     this.timezone = timezone;
    //     this.getDisputes();
    //   }
    // });


    this.filters = this.filterService.getDisputeFiltersArray();
    this.admin_name = this._local.customGetItem('admin_name');
    this.timezoneName = this._shared.getTimezoneName();
    this.route.queryParams.subscribe((data: any) => {
      if (data) {
        this.show_filters = data.showFilterTab ? data.showFilterTab : this.show_filters;
        this.resData.pageNumber = data.page ? data.page : 1;
        this.patient_filter_date = data.date ? new Date(2024, 6, 2) : null;
        this.dispute_filter = data.dF || 'All';
        this.charge_filter = data.cF || 'All';
        this.reqData.contract_type = data.cT || 0;
        this.reqData.ez_plan_new = data.ez_plan_new || 0;
        this.search = data.search || '';
        this.is_dummy = data.isDummy;
        this.country_id = data.selected_country;
        this.currency_logo = '$';
        this.timezone = data.timezone;
        this.getDisputes();
      }
    });
  }


  getDisputes() {
    let data: any = {
      filter: 0,
      limit: 10,
      offset: (this.resData.pageNumber - 1) * 10,
      search_string: this.search,
      charge_filter: this.charge_filter == 'All' ? '' : this.charge_filter,

    };
    if (data.search_string == '') {
      delete data.search_string;
    }
    if (this.reqData.contract_type != 0) {
      data['contract_type'] = this.reqData.contract_type;
      data['ez_plan_new'] = this.reqData.ez_plan_new;
    }
    if (this.dispute_filter != 'All') {
      data['filter'] = 1;
    }
    if (this.dispute_filter == 'All' && this.patient_filter_date) {
      data['filter'] = 2;
    }
    data['status'] = this.dispute_filter;
    if (this.patient_filter_date) {
      this.resetBtn = true;
      data['date'] = moment(this.patient_filter_date).format('YYYY-MM-DD')
    } else {
      this.resetBtn = false;
    }
    this._api.api(data, 'finance/get_disputes_list', 'get').subscribe({
      next: (data: any) => {
        this.contracts = data.disputes;
        this.resData.totalRecords = data.count;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  filterValues(event: any) {
    this.resData.pageNumber = 1;
    this.patient_filter_date = this.datePipe.transform(event.data.date, "yyyy-MM-dd");
    this.dispute_filter = event.data.dispute_status;
    this.charge_filter = event.data.charge_status;
    this.reqData.contract_type = event.data.type;
    if (event.data.type == "8") {
      this.reqData.ez_plan_new = 1;
      this.reqData.contract_type = 4;
    } else {
      this.reqData.ez_plan_new = 0;
      this.reqData.contract_type = 4;
    }
    this.search = event.data.search;
    this.modifyQueryParams();
  }

  modifyQueryParams() {
    let params = {
      page: this.resData.pageNumber,
      date: this.patient_filter_date,
      dF: this.dispute_filter,
      cF: this.charge_filter,
      cT: this.reqData.contract_type,
      type: this.reqData.contract_type,
      search: this.search,
      showFilterTab: this.show_filters,
      ez_plan_new: this.reqData.ez_plan_new
    }
    this._router.navigate(["/app/contracts/disputed"], {
      queryParams: params,
      queryParamsHandling: "merge"
    });
  }

  resetFilters(event: any) {
    this.resData.pageNumber = 1;
    this.patient_filter_date = null;
    this.charge_filter = 'All';
    this.dispute_filter = 'All';
    this.reqData.contract_type = 0;
    this.search = '';
    this.modifyQueryParams();
  }


  showFilter(value: any) {
    this.show_filters = value;
  }

  pageChanged(event: any) {
    this.resData.pageNumber = event.page;

    this.offset = (event.page - 1) * 10;
    this._router.navigate(['/app/contracts/disputed'], { queryParams: { page: this.resData.pageNumber }, queryParamsHandling: 'merge' });
  }

  chargeDoctor(contract: any) {
    contract['maxManualAmount'] = parseFloat((contract.amount_disputed + contract.total_refunded_amount + 10).toFixed(2));
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.chargeDoctorModal,
        extra_data: {
          contract: contract,
          countryData: this.countryData,
          currencyData: this.currencyData
        }
      },
      class: 'modal-dialog-centered '
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getDisputes();
      }
    })
  }

  sendBackPop(contract: any) {
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.sendWarningMail,
        extra_data: {
          contract: contract,
          countryData: this.countryData,
          currencyData: this.currencyData
        }
      },
      class: 'modal-dialog-centered '
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getDisputes();
      }
    })
  }

  exportXLSX(ext: any) {
    let data = {
      filter: 0,
      limit: this.resData.totalRecords,
      offset: 0,
      search_string: '',
      charge_filter: this.charge_filter == 'All' ? '' : this.charge_filter,
      contract_type: -1,
      ez_plan_new: -1,
      status: '',
      date: '',

    }

    if (this.reqData.contract_type != 0) {
      data['contract_type'] = this.reqData.contract_type;
      data['ez_plan_new'] = this.resData.ez_plan_new;
    }

    if (this.dispute_filter != 'All') {
      data['filter'] = 1;
    }
    if (this.dispute_filter == 'All' && this.patient_filter_date) {
      data['filter'] = 2;
    }
    data['status'] = this.dispute_filter;
    if (this.patient_filter_date) {
      data['date'] = moment(this.patient_filter_date).format('YYYY-MM-DD')
    }
    /* if (vm.end_date) {
        data.date = vm.end_date;
    } */
    if (this.search && this.search.length > 0) {
      data['search_string'] = this.search;
    }

    if (this.reqData.contract_type != 0) {
      data['contract_type'] = this.reqData.contract_type;
    }

    if (this.dispute_filter != 'All') {
      data['filter'] = 1;
    }
    if (this.dispute_filter == 'All' && this.patient_filter_date) {
      data['filter'] = 2;
    }
    data['status'] = this.dispute_filter;
    if (this.patient_filter_date) {
      data['date'] = moment(this.patient_filter_date).format('YYYY-MM-DD')
    }
    if (this.search && this.search.length > 0) {
      data['offset'] = 0;
      data['search_string'] = this.search;
      this.hadSearched = true;
      this.resData.pageNumber = 1;
    } else {
      this.hadSearched = false;
      //delete data.search_string;
    }
    this._api.api(data, 'finance/get_disputes_list', "get")
      .subscribe({
        next: (data: any) => {
          let list = [];
          let contracts = data.disputes;
          list[0] = {
            created: 'Disputed On',
            contract_code: 'Contract ID/Payment ID',
            contract_type: 'Contract Type',
            patient_name: 'Customer Details',
            amount_deducted: 'Amount Deducted($)',
            payment_from: 'Payment From',
            amount_disputed: 'Amount Disputed($)',
            amount_refunded: 'Amount Refunded($)',
            charge_from_doctor: 'Charge from Business($)',
            charged_from_doctor: 'Charged from Business',
            refunded_to_customer: 'Refunded to Customer($)',
            payment_type: 'Payment Type',
            reason: 'Reason',
            status: 'Status',
            doctor_name: 'Business Owner Name',
            practice_name: 'Business Name',
          };
          for (var i = 0; i < contracts.length; i++) {
            list[i + 1] = {};
            list[i + 1].created = this.datePipe.transform(contracts[i].created, 'MM-dd-yyyy HH:mm a', this.timezone) ?? '';
            list[i + 1].contract_code = contracts[i].is_ppr == 1 ? contracts[i].payment_process_code : contracts[i].contract_code;
            list[i + 1].contract_type = (contracts[i].is_ppr == 1 && contracts[i].is_ar == 0 ? 'Payment Processing' : (contracts[i].contract_type == 1 ? '10% Platform Fee' : (contracts[i].is_ppr == 1 && contracts[i].is_ar == 1 ? 'Accounts Receivable' : contracts[i].contract_type == 3 ? 'KeepeeFi Financing' : contracts[i].contract_type == 4 ? (contracts[i].ez_plan_new ? 'ZeroFi Financing' : 'SimpleeFi Financing') : contracts[i].contract_type == 6 ? 'SimpleeFi Financing (With Deferred Interest)' : contracts[i].contract_type == 5 ? 'Debt Recovery Payment Plan' : 'CredeeFi Financing')));
            list[i + 1].patient_name = contracts[i].patient_name ? ((contracts[i].patient_title ? contracts[i].patient_title + ' ' : '') + contracts[i].patient_name +
              (contracts[i].patient_mobile ? ', ' + contracts[i].patient_mobile : '') +
              (contracts[i].patient_email ? ', ' + contracts[i].patient_email : '')) : '';
            list[i + 1].amount_deducted = contracts[i].amount_deducted;
            list[i + 1].payment_from = contracts[i].ach_id ? 'Bank' : 'Card';
            list[i + 1].amount_disputed = contracts[i].amount_disputed == 'Not yet debited' ? 'Not ye debited' : contracts[i].amount_disputed;
            list[i + 1].amount_refunded = (contracts[i].total_refunded_amount - contracts[i].refunded).toString();
            if (contracts[i].amount_charged_from_doctor && (contracts[i].doctor_transfer_id && contracts[i].doctor_transfer_id != null)) {
              list[i + 1].charge_from_doctor = contracts[i].amount_charged_from_doctor ? (contracts[i].amount_charged_from_doctor) : '0.00';
            } else if (contracts[i].charge_from_doctor && (!contracts[i].doctor_transfer_id || contracts[i].doctor_transfer_id == null)) {
              list[i + 1].charge_from_doctor = contracts[i].charge_from_doctor ? (contracts[i].charge_from_doctor) : '0.00';
            } else {
              list[i + 1].charge_from_doctor = '$0.00';
            }

            if (contracts[i].doctor_transfer_id && contracts[i].transfer_reversal_id) {
              list[i + 1].charged_from_doctor = 'Charge Refunded';
            } else if (contracts[i].doctor_transfer_id && !contracts[i].transfer_reversal_id) {
              list[i + 1].charged_from_doctor = 'Yes';
            } else if (!contracts[i].doctor_transfer_id && !contracts[i].transfer_reversal_id) {
              list[i + 1].charged_from_doctor = 'No';
            } else {
              list[i + 1].charged_from_doctor = 'No';
            }
            if (contracts[i].take_back_email) {
              list[i + 1].charged_from_doctor += ' (Warning Email Sent)';
            }

            list[i + 1].refunded_to_customer = contracts[i].total_refunded_amount;
            list[i + 1].payment_type = contracts[i].payment_type;
            list[i + 1].reason = contracts[i].reason;
            list[i + 1].status = contracts[i].status;
            list[i + 1].doctor_name = contracts[i].doctor_name ? contracts[i].doctor_name : '-';
            list[i + 1].practice_name = contracts[i].practice_name ? contracts[i].practice_name : '-'
          }
          if (ext == 0) {
            this._shared.exportAsExcelFile(list, 'disputes', '.xlsx');
          } else if (ext == 1) {
            this._shared.exportAsExcelFile(list, 'disputes', '.csv');
          }
        },
        error: (error: any) => {
          console.log(error);
        }
      })

  }

  chargeDisputePop(contract: any) {
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.chargeDispute,
        extra_data: {
          contract: contract,
          countryData: this.countryData,
          currencyData: this.currencyData
        }
      },
      class: 'modal-dialog-centered '
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getDisputes();
      }
    })
  }

  refundPop(contract: any) {

    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.refundPopmodal,
        extra_data: {
          contract: contract,
          countryData: this.countryData,
          currencyData: this.currencyData
        }
      },
      class: 'modal-dialog-centered '
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getDisputes();
      }
    })

  }

  moveRemoveUnpaidPop(contract: any) {
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.moveRemoveUnpaidmodal,
        extra_data: {
          contract: contract,
          countryData: this.countryData,
          currencyData: this.currencyData
        }
      },
      class: 'modal-dialog-centered '
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getDisputes();
      }
    })
  }

}
