import { environment } from "src/environments/environment";
import { ApiService } from "./api.service";
import { localStorageService } from "./localstorage.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, distinctUntilChanged, Observable, Subject } from "rxjs";
import { RouteAuthService } from "./route-auth.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class MainService {

  settlement_new_card_metadata: any;
  public env = environment;
  noteadded = false;

  // public timezone$: BehaviorSubject<any> = new BehaviorSubject('-700');

  // public timezoneName$: BehaviorSubject<any> = new BehaviorSubject('');

  // public zone$: BehaviorSubject<any> = new BehaviorSubject('');

  public timezoneList$: BehaviorSubject<any> = new BehaviorSubject([]);

  public global_admin_list$: BehaviorSubject<any> = new BehaviorSubject([]);

  public currency_data$: BehaviorSubject<any>  = new BehaviorSubject([]);

  public country_data$: BehaviorSubject<any>  = new BehaviorSubject([]);

  public selected_country$: BehaviorSubject<any>  = new BehaviorSubject([]);

  public mobile_code_arr$: BehaviorSubject<any>  = new BehaviorSubject([]);

  public language_data$: BehaviorSubject<any>  = new BehaviorSubject([]);

  public salutations$: BehaviorSubject<any>  = new BehaviorSubject([]);

  public government_docs$: BehaviorSubject<any>  = new BehaviorSubject([]);

  public menu$: BehaviorSubject<any>  = new BehaviorSubject([]);

  public industryList$: BehaviorSubject<any>  = new BehaviorSubject([]);

  public stripeKey$: BehaviorSubject<any>  = new BehaviorSubject('');
  public systemSettings$: BehaviorSubject<any> = new BehaviorSubject([])

  toaster: any;
  stripe: any;
  stripe_element: any;
  // mask$: BehaviorSubject<any>  = new BehaviorSubject('');
  constructor(private _api: ApiService, public routerAuth: RouteAuthService, private _local: localStorageService, private _router: Router) {
    this.init();
  }

  init() {
    this._api.api({}, 'auth/get_settings', 'get')
      .subscribe((response: any) => {
        if (!response.is_error) {
          this.country_data$.next(response.country_data);
          this.salutations$.next(response.salutation);
          this.mobile_code_arr$.next(response.mobile_code_arr);
          this.language_data$.next(response.language_data);
          this.timezoneList$.next(response.timezones);
          this.stripeKey$.next(response.stripe_pk);
          this.industryList$.next(response.industries);
          this.menu$.next(response.menu);
          this.systemSettings$.next(response.system_settings);
          // console.log(this._local.customGetItem('timezone'));
          // if (this._local.customGetItem('timezone_val')) {
          //   const timeZ = response.timezones.find((tz: any) => tz.val == this._local.customGetItem('timezone_val'));
          //   this.timezone$.next(timeZ['val']);
          //   this.timezoneName$.next(timeZ['name']);
          //   this.zone$.next(timeZ['zone']);
          // } else {
          //   if (response.timezones && response.timezones.length) {
          //     this.timezone$.next(response.timezones[0].val);
          //     this.timezoneName$.next(response.timezones[0].name);
          //     this.zone$.next(response.timezones[0].zone);
          //     this.government_docs$.next(response.government_docs);
          //     this._local.customSetItem('timezone_val', response.timezones[0].val)
          //   }
          // }

          if (this._local.customGetItem('country_id')) {
            const country = response.country_data.find((country: any) => country.country_id == this._local.customGetItem('country_id'));
            this.selected_country$.next(country);
          } else {
            if (response.country_data && response.country_data.length) {
              this.selected_country$.next(response.country_data[0]);
              this._local.customSetItem('country_id', response.country_data[0].country_id)
            }
          }
        }
      })
      if (this._local.customGetItem('token')){
        this.fetchAdminList();
      }
  }

  fetchAdminList() {
    if (this._local.customGetItem('adminList')){
      this.global_admin_list$.next(this._local.customGetItem('adminList'));
    } else {
      let data = {
        limit: -1
      };
      this._api.api(data, 'user/fetch_admin_list', 'get')
        .subscribe((data: any) => {
          if (data && data.data && data.data.length) {
            this.global_admin_list$.next(data.data);
            this._local.customSetItem('adminList', data.data);
          }
        });
    }
  }

  getNameFromArray(array: Array<[]>, id: number, key_id: string, return_key: string) {
    let name: any = array.find((obj: any) => {
      return obj[key_id] == id;
    });
    return name[return_key];
  }

  copyToClipboard(text: string) {
    // Create a temporary text area element
    let returnData;
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    try {
      // Select and copy the text using the Clipboard API
      textarea.select();
      document.execCommand('copy');
      returnData = true;
    } catch (err) {
      console.error('Unable to copy to clipboard:', err);
      returnData = err;
    } finally {
      // Clean up by removing the temporary element
      document.body.removeChild(textarea);
    }
    return { textCopied: returnData };
  }

  checkProfile() {
    if (this._local.customGetItem('reset_password_token')) {
      this._router.navigate(['new-password']);
      return;
    }
    // return true;
  }

  getTimezoneCountry(): Observable<any> {
    return combineLatest([
      this.timezoneList$.asObservable(),
      this.country_data$.asObservable(),
    ]).pipe(
      // Ensure it only emits when values change to avoid multiple calls
      distinctUntilChanged(
        (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
      )
    );
  }

  serverDate(date: any) {
    //convert MM-dd-yyyy to yyyy-MM-dd
    if (date == null) return date;
    var new_date = date.split('-');
    return new_date[2] + '-' + new_date[0] + '-' + new_date[1];
  }

  getUTCDate(date: string) {
    if (date == null) return '';
    var local = '';
    var t = date.split('T');
    if (t[1] == '') {
      return date;
    }
    if (date.indexOf('T') < 0) return date;
    var aD = t[0].split('-');
    local = '' + aD[1] + '-' + aD[2] + '-' + aD[0];
    return local;
  }

  schedulerUpdate(): Subject<MessageEvent> {
    const observable = new Observable(ob => {
      // this.socket.on('scheduler_update', (data: any) => {
      //   ob.next(data);
      // });
    });
    const observer = {
      next: (data: any) => {
        data['timezone'] = this._local.customGetItem('timezone');
        // this.socket.emit('auth', data);
      },
    };
    return Subject.create(observer, observable);
  }
  

}