<div class="card card-body border p-3" style="position: unset !important;">
  <div class="mb-2 border-bottom">
    <button class="btn rounded-0 rounded-top" [ngClass]="{'btn-primary':showNewNotes == 1}"
      (click)="showNewNotes = 1">Notes</button>
    <button class="btn rounded-0 rounded-top" [ngClass]="{'btn-primary':showNewNotes == 2}" (click)="showNewNotes = 2"
      *ngIf="!_shared.routerAuth.restricted_buttons.hidden_notes">Hidden Notes</button>
    <app-export-csr-notes label="Export Notes" class="pull-right" [notes]="allNoteList" [profile]="data"
        [type]="export_type" *ngIf="allNoteList && allNoteList.length">
      </app-export-csr-notes>
  </div>

  <div *ngIf="showNewNotes == 1">
    <!-- Filter -->
    <div class="row filter-box">
      <div class="col-sm-6 col-md-3">
        <div class="form-group position-relative mb-2">
          <label class="fs-14 fw-400 m-0">Select Date Range: </label>
            <div class="d-flex align-items-center input_field p-0 overflow-hidden">
              <input readonly type="text" #rangePicker="bsDaterangepicker" bsDaterangepicker class="input_field border-0 border-end m-0 flex-fill rounded-0"
                [(ngModel)]="dateRangeValue" (ngModelChange)="dateChange();getAssignments();" [bsConfig]="bsConfig">
            <button class="bg-transparent border-0 h-100 hw-40" *ngIf="!resetBtn" (click)="rangePicker.toggle()">
              <i class="fa fa-calendar"></i>
            </button>
            <button class="reset-btn bg-transparent border-0 h-100 hw-40" (click)="resetDate()" *ngIf="resetBtn">
              <i class="fa fa-close"></i>
            </button>
            </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4">
        <label class="fs-14 fw-400 m-0 mb-2">Select Admin</label>
        <ngx-select [items]="getAdminList" optionValueField="admin_id" optionTextField="admin_name"
          (select)="selectAdmin($event)" [allowClear]="true" (selectionChanges)="adminClear($event)"
          placeholder="please enter admin name" [defaultValue]="dataArguements.admin_id">
          <ng-template ngx-select-option-selected let-option>
            {{option.data.admin_name + ' ('+option.data.admin_email+')'}}
          </ng-template>
          <ng-template ngx-select-option let-option let-text="text">
            {{option.data.admin_name}} <br>
            <small>{{option.data.admin_email}}</small>
          </ng-template>
          <ng-template ngx-select-option-not-found>
            Not found
          </ng-template>
        </ngx-select>
      </div>
      <div class="col-sm-6 col-md-3">
        <label class="fs-14 fw-400 m-0">Search <span><i tooltip="Search by all field's data" class="fa fa-info-circle"></i></span></label>
        <div class="input-group">
          <input type="text" class="input_field" [(ngModel)]="dataArguements.search_string"
            (keyup.enter)="dataArguements.search_string && searchRecords();getAssignments();"
            placeholder="Type and press enter to search" required>
          <div class="input-group-append" *ngIf="dataArguements.search_string">
            <button class="btn btn-default rounded" (click)="dataArguements.search_string='';resetSearch()">
              <i class="fa fa-close"></i></button>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-2">
        <label class="fs-14 fw-400 m-0">Notes with ticket:</label>
        <div class="input-group">
          <select id="reminder_type" class="input_field" name="reminder_id" [(ngModel)]="selectedNotesTicket"
            (change)="filterNotesWithTicekt(selectedNotesTicket)">
            <option value="{{item.value}}" *ngFor="let item of notesTicket">{{item.label}}</option>
          </select>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 mb-3" *ngIf="selectedNotesTicket == 1 || selectedNotesTicket == 0">
        <label class="me-2 fs-14 fw-600 text-grey">Filter By: </label>
        <div class="d-flex ">
          <span class="me-3">
            <input tappable  [(ngModel)]="filter.pending" type="checkbox" class="secondary-checkbox" name="filter"
              value="0" id="pending_filter" (change)="onChangeFilters(0)">
              <label tappable class="fs-14 fw-400 m-0 text-grey" for="pending_filter">Pending</label>
          </span>
          <span class="me-3">
            <input tappable  [(ngModel)]="filter.follow_up" type="checkbox" class="secondary-checkbox" name="filter"
            value="3" id="folloup_filter" (change)="onChangeFilters(3)">
            <label tappable class="fs-14 fw-400 m-0 text-grey" for="folloup_filter">Follow Up</label>
          </span>
          <span class="me-3">
            <input tappable  [(ngModel)]="filter.re_open" type="checkbox" class="secondary-checkbox" name="filter"
              value="2" id="reopen_filter" (change)="onChangeFilters(2)">
              <label tappable class="fs-14 fw-400 m-0 text-grey" for="reopen_filter">Re-Opened</label>
          </span>
          <span class="me-3">
            <input tappable  [(ngModel)]="filter.closed" type="checkbox" class="secondary-checkbox" name="filter" value="1"
              id="closed_filter" (change)="onChangeFilters(1)">
              <label tappable class="fs-14 fw-400 m-0 text-grey" for="closed_filter">Closed</label>
          </span>
          <div class=" "
            *ngIf="filter.pending || filter.follow_up || filter.re_open || filter.closed"
            (click)="filterNotesWithTicekt(2)">
            <a href="javascript:void(0)">Clear All</a>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="(allNoteList && allNoteList.length) || loader; else emptyNewNotes">
      <div class="p-0">
        <div class="b">
          <div class="card-header">{{timezone?'(Time in ' + timezoneName+')':''}}</div>
          <div class="text-center doctor-loader" *ngIf="loader; else loaderFalse">
            <div class="line-scale">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <ng-template #loaderFalse>
            <div class="card-body">
              <div class="text-center border bg-secondary p-4  rounded" *ngIf="allNoteList && allNoteList.length == 0">
                <span class="fa fa-exclamation-triangle fa-5x"></span>
                <p class="m-0 mt-2">Notes not added yet!</p>
              </div>
              <div  class="mxh-500 overflow-auto">
                <div class="mb-3 p-2 px-3 rounded note-item border" *ngFor="let admin of allNoteList">
                  <div class="d-flex align-items-center mb-1">
                    <i tappable class="fa fa-sign-in mr-2" [tooltip]="'Incoming Call'"
                      *ngIf="admin.contact_type==1"></i>
                    <i tappable class="fa fa-sign-out mr-2" [tooltip]="'Outgoing Call'"
                      *ngIf="admin.contact_type==2"></i>
                    <i tappable class="fa fa-envelope mr-2" [tooltip]="'Email'"
                      *ngIf="admin.contact_type==3 || admin.contact_type==9"></i>
                    <i tappable class="fa fa-commenting-o mr-2" [tooltip]="'Text'"
                      *ngIf="admin.contact_type==4 || admin.contact_type==9"></i>
                    <i tappable class="fa fa-commenting-o mr-2" [tooltip]="'Chat'" *ngIf="admin.contact_type==5"></i>
                    <i tappable class="fa fa-commenting-o mr-2" [tooltip]="'Verification Call'"
                      *ngIf="admin.contact_type==6"></i>
                    <i tappable class="fa fa-commenting-o mr-2" [tooltip]="'Potential Business'"
                      *ngIf="admin.contact_type==7"></i>
                    <i tappable class="fa fa-commenting-o mr-2" [tooltip]="'Validation'"
                      *ngIf="admin.contact_type==8"></i>
                    <p class="m-0 fs-14 fw-500 text-grey">
                      <strong class="admin_name">{{admin.admin_name || admin.name}}</strong>
                      {{admin.date_added | date:'medium':timezone}}
                      <i class="fa fa-3x fs-24 px-2" [ngClass]="admin.customer_status == 1 ? 'fa-smile-o text-success' : 'fa-frown-o text-warning'" [tooltip]="admin.customer_status == 1 ? 'Happy': 'Sad'"></i>
                      <span *ngIf="admin.is_issue == 1" class="badge bg-warning ml-2">high priority</span>
                      <a *ngIf="admin && admin.req_id" href="javascript:void(0)"
                        [routerLink]="['/app/assignments/my-assignments/', admin.req_id]">{{admin.req_id}} <span
                          [ngClass]="{'text-success': admin.status == '1', 'text-danger':admin.status == '2', 'text-primary': admin.status == '3', 'text-warning': admin.status == '0'}">({{admin.status
                          == 0 ? 'Pending' : (admin.status == 1 ? 'Closed' : (admin.status == 2 ? 'Re-open' :
                          'Follow-up')
                          ) }}) </span></a> <i class="fa fa-eye" *ngIf="admin && admin.req_id" placement="right"
                        containerClass="tooltip-comments" (click)="getTicketComments(admin.req_id)"
                        [popover]="tolTemplate" container="body" [outsideClick]="true"></i>
                      <!--  -->
                      <ng-template #tolTemplate>
                        <div *ngFor="let d of latestTicketComment; let i=index">
                          <div *ngIf="d.id == admin.req_id && d.notes.length ">
                            <ul *ngFor="let comment of d.notes; let i=index">
                              <li *ngIf="i<5">
                                {{comment.note}}
                              </li>
                            </ul>
                          </div>
                          <span *ngIf="d.id == admin.req_id && !d.notes.length">No Comments</span>
                        </div>
                      </ng-template>
                    </p>
                  </div>
                  <div class="notes-block" [ngClass]="{'note-added': admin.class}">
                    <p class="mb-0 fs-14 fw-500 text-grey">
                      <span *ngIf="admin && admin.action" class="pr-1"><strong>Action:</strong>
                        {{admin.action}}</span>
                      <span *ngIf="admin && admin.amount && currencyData" class="pr-1"><br /><strong>Amount:</strong>
                        {{currencyData.display_logo}}{{admin.amount | number}}</span>
                      <span *ngIf="admin && admin.payment_mode" class="pr-1"><br /><strong>Payment Mode:</strong>
                        {{admin.payment_mode}}</span>
                      <span *ngIf="admin && admin.missing_count" class="pr-1"><strong>Missing Count:</strong>
                        {{admin.missing_count}}</span>
                      <span *ngIf="admin && admin.next_payment_date" class="pr-1"><br /><strong>Next Payment
                          Date:</strong>
                        {{admin.next_payment_date | date:'MM-dd-yyyy':'+0000'}}</span>
                      <span
                        *ngIf="(admin && admin.transaction_initiated_date && admin.transaction_initiated_date!=null)"
                        class="pr-1"><br /><strong>Transaction Initiated Date:</strong>
                        {{admin.transaction_initiated_date | date:'MM-dd-yyyy':timezone}}</span>
                      <span *ngIf="(admin && admin.payment_date && admin.payment_date!=null)"
                        class="pr-1"><br /><strong>Payment Date:</strong>
                        {{admin.payment_date | date:'MM-dd-yyyy':timezone}}</span>
                      <span *ngIf="admin && admin.bankruptcy_status" class="pr-1"><br /><strong>Bankruptcy
                          Status:</strong>
                        {{admin.bankruptcy_status}}</span>
                    </p>
                    <p class="mb-0 fs-14 fw-500 text-grey">
                      <span *ngIf="admin && (admin.notes || admin.note || admin.arrangement_note)"
                        class="show-enter"><strong>Notes: </strong>
                        <span [innerHtml]="admin.notes || admin.note || admin.arrangement_note"></span>
                      </span>
                    </p>
                    <div *ngIf="admin && admin.csr_docs_urls && admin.csr_docs_urls.length>0"
                      class="d-flex align-items-center">
                      <a tappable [href]="doc.file_url" target="_blank"
                        class="d-flex align-items-center justify-content-center m-1 border bg-dark"
                        *ngFor="let doc of admin.csr_docs_urls" style="height: 80px; width: 80px;">
                        <img [src]="doc.thumb_url" alt="" class="img-fluid"
                          onError="this.src='assets/images/png/attachment.png';">
                      </a>
                    </div>

                  </div>
                  <p *ngIf="admin && admin.assigned_name || admin.followup_date">
                    <i tappable class="fa fa-user mr-2"
                      [tooltip]="'Assigned to'"></i><b>{{admin.assigned_name?admin.assigned_name:'Not Assigned
                      Yet'}}</b>
                    |
                    <i tappable class="fa fa-calendar mr-2"
                      [tooltip]="'Follow up'"></i><b>{{admin.followup_date?(admin.followup_date | date:'MM/dd/yyyy
                      hh:mm:ss aaa': timezone):'Not Available'}}</b>
                    <br>
                  </p>
                  <p *ngIf="admin.is_deleted" class="text-danger m-0">This note has been deleted.</p>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <ng-template #emptyNewNotes>
      <div class="col-12 p-4 border text-center" *ngIf="!loader">
        <i class="fa fa-exclamation-triangle fa-5x"></i>
        <p>No notes added yet!</p>
        <p><a href="javascript:void(0)" (click)="addContractNotesModal()">Click here</a> to add a new note.</p>
      </div>
    </ng-template>
  </div>

  <div *ngIf="showNewNotes == 2">
    <app-notes-list [type]="export_type" [id]="notesId"></app-notes-list>
  </div>
</div>
