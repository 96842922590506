import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { MainService } from '../../shared/main.service';
import { localStorageService } from '../../shared/localstorage.service';
import { SharedService } from '../../shared/shared.service';
import { ApiService } from '../../shared/api.service';
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DatePipe]
})
export class DashboardComponent implements OnInit {
  public old_dashboard: any = {
    active_doctors_this_month: 0,
    active_doctors_this_month_30: 0,
    active_doctors: 0,
    active_doctors_today: 0,
    total_disabled_accounts: 0,
    active_partners: 0,
    new_partners_today: 0,
    active_patients: 0,
    patients_without_contracts: 0,
    new_patients_today: 0,
    self_verified_customers: 0,
    financed_contracts: 0,
    financed_contracts_today: 0,
    overdue_accounts: 0,
    legal_overdue_contracts: 0,
    financing_errors: 0,
    late_fee: 0,
    late_fee_count: 0,
    late_fee_today: 0,
    late_fee_count_today: 0,
    financed_amount: 0,
    financed_amount_today: 0,
    claim_requests: 0,
    claim_requests_today: 0,
    claim_request_amount: 0,
    claim_request_amount_today: 0,
    claim_request_paid: 0,
    claim_request_paid_today: 0,
    zero_percent_contracts:0,
    ten_percent_contracts:0,
    upfront_fee:0,
    zero_percent_today_contracts:0,
    ten_percent_today_contracts:0,
    today_upfront_fee:0,
    today_first_recurring_fee:0,
    first_recurring_fee:0,
    contract_financed_requests: 0,
    deleted_split_requests: 0,
    link_opened_requests: 0,
    plan_selected_requests: 0,
    today_contract_financed_requests: 0,
    today_plan_selected_requests: 0,
    today_split_requests: 0,
    total_split_requests: 0,
    split_errors: 0,
    demand_letter_stats_data: {
      scanned: 0,
      total: 0,
      not_scanned: 0,
      completed_contracts: 0,
      active_contracts: 0,
      overdue_contracts: 0,
      cancelled_contracts: 0,
      total_settlement_contracts: 0,
      settlement_financed_amount: 0.00,
      active_total_amt: 0,
      active_total_pod: 0,
      active_total_pop: 0,
      complete_total_amt: 0,
      complete_total_pod: 0,
      complete_total_pop: 0
    }
  };
  public dashboard: any = {
    active_doctors_this_month: 0,
    active_doctors_this_month_30: 0,
    active_doctors: 0,
    active_doctors_today: 0,
    total_disabled_accounts: 0,
    active_partners: 0,
    new_partners_today: 0,
    active_patients: 0,
    patients_without_contracts: 0,
    new_patients_today: 0,
    self_verified_customers: 0,
    financed_contracts: 0,
    financed_contracts_today: 0,
    overdue_accounts: 0,
    legal_overdue_contracts: 0,
    financing_errors: 0,
    late_fee: 0,
    late_fee_count: 0,
    late_fee_today: 0,
    late_fee_count_today: 0,
    financed_amount: 0,
    financed_amount_today: 0,
    claim_requests: 0,
    claim_requests_today: 0,
    claim_request_amount: 0,
    claim_request_amount_today: 0,
    claim_request_paid: 0,
    claim_request_paid_today: 0,
    zero_percent_contracts:0,
    ten_percent_contracts:0,
    upfront_fee:0,
    zero_percent_today_contracts:0,
    ten_percent_today_contracts:0,
    today_upfront_fee:0,
    today_first_recurring_fee:0,
    first_recurring_fee:0,
    split_errors: 0
  };

  public pp_dashboard: any = {
    today_pp_payments: 0,
    today_pp_amount: 0,
    last_month_pp_payments: 0,
    last_month_pp_amount: 0,
    total_pp_payments: 0,
    total_pp_amount: 0,
    today_ar_payments: 0,
    today_ar_amount: 0,
    last_month_ar_payments: 0,
    last_month_ar_amount: 0,
    total_ar_payments: 0,
    total_ar_amount: 0
  };
  is_dummy: any = this._local.customGetItem('is_dummy') ? this._local.customGetItem('is_dummy') : 0;
  subscription: any;
  timezone_subscription: any;
  opened: boolean = true;
  // firstOpened: boolean = true;
  // collapsible: boolean = false;

  messages!: Subject<any>;
  timezone: any = this._shared.getTimezone();
  cocu_id: any = this._local.customGetItem('cocu_id');
  country_id: any = this._local.customGetItem('country_id');
  minDate: any = new Date(2017, 0, 1);
  maxDate: any;
  dateRangeValue: any ;
  today_start_date: any;
  today_end_date: any;
  country_sign: string = '$';
  country_subscription: any;
  minDates: any;
  constructor(
    public mainService: MainService,
    private router: Router, private _local: localStorageService, private _ar: ActivatedRoute, public _shared: SharedService, private _api: ApiService,
    private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.country_subscription = this._ar.queryParams.subscribe((data: any) => {
      if (data) {

        this.cocu_id = data.cocu_id || this._local.customGetItem('cocu_id');
        this.country_sign =  data.display_logo,
        this.country_id = data.country_id;
        this.is_dummy = data.is_dummy;
        this.timezone = data.timezone;
        this.maxDate = new Date(this._shared.getMaxDate(this.timezone));
        this.dateRangeValue = [this.minDate, this.maxDate];
        this.getDashboardData();
        this.getProcessPaymentDashboardData();
      }
    });

    this.getDashboardData();
    this.getProcessPaymentDashboardData();
    // this.subscription = this.mainService.getMessage().subscribe((value: any) => {
    //   this.getDashboardData();
    //   this.getProcessPaymentDashboardData();
    // });
    // this.timezone_subscription = this.mainService.getCurrentTimezone().subscribe((value: any) => {
    //   this.getDashboardData();
    //   this.getProcessPaymentDashboardData();
    // });
    this.maxDate = new Date(this._shared.getMaxDate(this.timezone));
    
    this.dateRangeValue = [this.minDate, this.maxDate];
    this.minDates =this.datePipe.transform(this.minDate,'yyyy-MM-dd')
    this.today_start_date = this.datePipe.transform(this.maxDate, 'yyyy-MM-dd');
    this.today_end_date = this.datePipe.transform(this.maxDate, 'yyyy-MM-dd');

    // this.dashboardService.messages.subscribe((msg: any) => {
    //   console.log(msg);
    //   if (msg && msg.data) {
    //     this.dashboard = msg.data;
    //   }
    //   if (msg && msg.data && msg.data.last_month_pp_payments != undefined) {
    //     this.pp_dashboard = {
    //       today_pp_payments: msg.data.today_pp_payments,
    //       today_pp_amount: msg.data.today_pp_amount,
    //       last_month_pp_payments: msg.data.last_month_pp_payments,
    //       last_month_pp_amount: msg.data.last_month_pp_amount,
    //       total_pp_payments: msg.data.total_pp_payments,
    //       total_pp_amount: msg.data.total_pp_amount,
    //       today_ar_payments: msg.data.today_ar_payments,
    //       today_ar_amount: msg.data.today_ar_amount,
    //       last_month_ar_payments: msg.data.last_month_ar_payments,
    //       last_month_ar_amount: msg.data.last_month_ar_amount,
    //       total_ar_payments: msg.data.total_ar_payments,
    //       total_ar_amount: msg.data.total_ar_amount
    //     };
    //   }
    // });
    // this.sendMessage();
  }

  // sendMessage() {
  //   this.dashboardService.sendMsg({
  //     is_dummy: this.is_dummy
  //   });
  // }

  collapseHandler(value: any) {
    console.log(value);
    this.opened = value;
    // this.firstOpened = value;
  }

  ngOnDestroy() {
    // this.subscription.unsubscribe();
    // this.timezone_subscription.unsubscribe();
    this.country_subscription.unsubscribe();
  }
  getSelectedTimezoneOffset() {
    if (this.timezone) {
      return (parseInt(this.timezone.substring(0, this.timezone.length - 2)) * 60) + parseInt(this.timezone.substring(this.timezone.length - 2))
    } else {
      return null;
    }
  }
  getDashboardData() {
    let data = {
      is_dummy: this.is_dummy,
      timezone_offset: this.getSelectedTimezoneOffset(),
      cocu_id: this.cocu_id

    };
    console.log( this.cocu_id);
    this._api.api(data, 'dashboard/get_data_for_dashboard', 'get')
      .subscribe((data: any) => {
        if (data && data.data) {
          this.dashboard = data.data;
        }
      }, (error: any) => {
        console.log(error);
      });
  }

  getProcessPaymentDashboardData() {
    let data = {
      is_dummy: this.is_dummy,
      timezone_offset: this.getSelectedTimezoneOffset(),
      country_id:this.country_id
    };
    this._api.api(data, 'dashboard/payment_process_dashboard_data', 'get')
      .subscribe((data: any) => {
        if (data && data.final_result) {
          this.pp_dashboard = data.final_result;
        }
      }, (error: any) => {
        console.log(error);
      });
  }

}
