<div class="main-wrapper d-flex flex-column p-3">

    <div class="d-flex justify-content-between px-0 py2 border-bottom align-items-center  hw-65 w-100">
        <h3 class="m-0 fs-24 fw-700 text-secondary">Disputed Payments</h3>
        <span class="bg-primary rounded py-2 px-3 fs-18 fw-600"> Total Disputes :
            {{resData.totalRecords?resData.totalRecords:0}}</span>

    </div>
    <div [hidden]="!show_filters">
        <app-filters class="row m-0 w-100" [filters]="filters" (finalValues)="filterValues($event)"
            (resetAllFilters)="resetFilters($event)">
        </app-filters>
    </div>
    <div class="m-0 card card-body flex-fill">

        <div class=" pr-0 text-end">
            <button (click)="showFilter(!show_filters)" type="button" class="btn btn btn-primary rounded-pill py-1 px-2 me-3" [ngClass]="{'btn-primary':show_filters, 'btn-outline': !show_filters}" >
                <span class="fs-16 me-1">{{show_filters ? "Hide Filters" : "Show Filters"}}</span>
                <i class="fa fa-filter"></i>
            </button>
            
            <div class="btn-group" dropdown *ngIf="!mainService.routerAuth.restricted_buttons.export">
                <button *ngIf="contracts && contracts.length" id="button-basic" dropdownToggle type="button"
                    class="btn btn-primary rounded-pill py-1 px-2 dropdown-toggle" aria-controls="dropdown-basic">
                    Export <span class="caret"></span>
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li role="menuitem">
                        <button class="dropdown-item" (click)="exportXLSX(0)" tappable>Export XLSX</button>
                      
                    </li>
                    <li role="menuitem">
                        <button class="dropdown-item" (click)="exportXLSX(1)"tappable>Export CSV</button>
                        
                    </li>
                </ul>
            </div>
        </div>
        <div class="table-responsive  w-100 overflow-auto flex-fill show-scroll-bar py-2">
            <table class="table" *ngIf="contracts && contracts.length; else noData">
                <thead>
                    <tr>
                        <th class="fs-16 fw-400">S No.</th>
                        <th class="fs-16 fw-400">Disputed On</th>
                        <th class="fs-16 fw-400">Contract ID/Payment ID</th>
                        <th class="fs-16 fw-400">Customer Details</th>
                        <th class="fs-16 fw-400">Amount Deducted</th>
                        <th class="fs-16 fw-400">Payment From</th>
                        <th class="fs-16 fw-400">Amount Disputed</th>
                        <th class="fs-16 fw-400">Amount Refunded</th>
                        <th class="fs-16 fw-400">Charge from Business</th>
                        <th class="fs-16 fw-400">Payment Type</th>
                        <th class="fs-16 fw-400">Reason</th>
                        <th class="fs-16 fw-400">Status</th>
                        <th class="fs-16 fw-400">Business Owner Name</th>
                        <th class="fs-16 fw-400">Business Name</th>
                        <th class="fs-16 fw-400">Charged from Business</th>
                        <th class="fs-16 fw-400 btn">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let contract of contracts;let i=index">
                        <td>{{((resData.pageNumber-1)*10)+(i+1)}}</td>
                        <td>{{contract.created | date:'MM-dd-yyyy':timezone}}</td>
                        <td>
                            <a *ngIf="!contract.is_ppr" class="padd_r5"
                                [routerLink]="'/app/contracts/details/'+contract.contract_id">
                                {{contract.contract_code}}
                            </a><span *ngIf="!contract.is_ppr"> - {{contract.contract_status}}</span>
                            <a *ngIf="contract.is_ppr == 1" class="padd_r5"
                                [routerLink]="'/app/payment-processing/'+contract.ppr_id">
                                {{contract.payment_process_code}}
                            </a>
                            <br />
                            <!-- <span class="badge bg-warning" *ngIf="contract.contract_type == 1">10% Platform Fee</span> -->
                            <span class="radius-8 badge bg-success fs-12 fw-400"
                                *ngIf="contract.contract_type == 2">CredeeFi
                                Financing</span>
                            <span class=" badge bg-primary radius-8" *ngIf="contract.contract_type == 3">KeepeeFi
                                Financing</span>
                            <span class="bg-primary badge radius-8"
                                *ngIf="contract.contract_type == 4">{{contract.ez_plan_new
                                ? 'ZeroFi Financing':
                                'SimpleeFi Financing'}}</span>
                            <span class="bg-primary badge radius-15 rounded"
                                *ngIf="contract.contract_type == 6">SimpleeFi Financing
                                (With Deferred Interest)</span>
                            <span class="bg-primary  badge radius-8" *ngIf="contract.contract_type == 5">Debt Recovery
                                Payment
                                Plan</span>
                            <span class="bg-info badge  radius-8"
                                *ngIf="contract.is_ppr == 1 && contract.is_ar == 0">Payment
                                Processing</span>
                            <span class="bg-info badge radius-8"
                                *ngIf="contract.is_ppr == 1 && contract.is_ar == 1">Credee
                                Bill</span>
                        </td>
                        <td>{{contract.patient_title?contract.patient_title+'
                            ':''}}{{contract.patient_name}}<br>{{contract.patient_mobile?'+1-'+(contract.patient_mobile):''}}<br>{{contract.patient_email}}
                        </td>
                        <td>{{contract.amount_deducted | currency:currency_logo}}</td>
                        <td>{{contract.ach_id ? 'Bank' : 'Card'}}</td>
                        <td>{{contract.amount_disputed=='Not yet debited'? 'Not yet debited': (contract.amount_disputed|
                            currency:currency_logo)}}
                        </td>
                        <td>{{(contract.total_refunded_amount - contract.refunded) | currency:currency_logo}}</td>
                        <td>
                            <span
                                *ngIf="contract.amount_charged_from_doctor && (contract.doctor_transfer_id && contract.doctor_transfer_id!=null)">
                                {{contract.amount_charged_from_doctor ?(contract.amount_charged_from_doctor |
                                currency:currency_logo) : '$0.00'}}
                            </span>
                            <span
                                *ngIf="contract.charge_from_doctor && (!contract.doctor_transfer_id || contract.doctor_transfer_id==null)">
                                {{contract.charge_from_doctor ?(contract.charge_from_doctor | currency:currency_logo):
                                '$0.00'}}
                            </span>
                            <span
                                *ngIf="contract.charge_from_doctor==0 && contract.amount_charged_from_doctor==0">{{'0.00'
                                | currency: currency_logo}}</span>
                            <i *ngIf="contract.total_refunded_amount > 0" class="fa fa-info-circle"
                                tooltip="Refunded to customer {{contract.total_refunded_amount | currency:currency_logo}}"></i>
                        </td>
                        <td>{{contract.payment_type}}</td>
                        <td>{{contract.reason}}</td>
                        <td>{{contract.status}}</td>
                        <td>
                            <a class="padd_r5" [routerLink]="'/app/business/details/'+contract.doctor_id"
                                class="text-primary text-bold">
                                {{contract.doctor_name?contract.doctor_name:'-'}}
                            </a>
                        </td>
                        <td>
                            {{contract.practice_name?contract.practice_name:'-'}}
                        </td>
                        <td>
                            <span *ngIf="contract.doctor_transfer_id && contract.transfer_reversal_id">Charge
                                Refunded</span>
                            <span *ngIf="contract.doctor_transfer_id && !contract.transfer_reversal_id">Yes</span>
                            <span *ngIf="!contract.doctor_transfer_id && !contract.transfer_reversal_id">No</span>
                            <span *ngIf="contract.take_back_email"><br>(Warning Email Sent)</span>
                        </td>
                        <td>
                            <div class="btn-group" dropdown>
                                <button id="button-basic" dropdownToggle type="button"
                                    class="btn btn-primary btn-sm rounded dropdown-toggle"
                                    aria-controls="dropdown-basic">
                                    Actions <span class="caret"></span>
                                </button>
                                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right animated"
                                    role="menu" aria-labelledby="button-basic">
                                    <li role="menuitem">
                                        <button class="dropdown-item" (click)="chargeDoctor(contract)"[disabled]="!(contract.doctor_transfer_id == null && contract.dispute_fee!='Not yet debited' && contract.refunded==false)||contract.status == 'Won'"
                                            >Charge
                                            Business</button>

                                    </li>
                                    <li role="menuitem">
                                        <button class="dropdown-item" (click)="sendBackPop(contract)"
                                            [disabled]="!(contract.doctor_transfer_id == null && contract.take_back_email_sent==false)">Send
                                            Warning Dispute Email</button>

                                    </li>
                                    <li role="menuitem">
                                        <button class="dropdown-item" (click)="chargeDisputePop(contract)"
                                            [disabled]="!(contract.doctor_transfer_id != null && contract.refunded==false && contract.status == 'Won' && contract.patient_charge_id == null) || contract.is_ppr == 1">Charge
                                            Dispute Fees from Customer
                                            Account</button>

                                    </li>
                                    <li role="menuitem">
                                        <button class="dropdown-item" (click)="refundPop(contract)"
                                            [disabled]="!(contract.doctor_transfer_id != null && contract.status=='Won' && contract.transfer_reversal_id==null)">Transfer
                                            back to Business</button>

                                    </li>
                                    <li role="menuitem">
                                        <button class="dropdown-item"
                                            [routerLink]="'/app/contracts/charges/'+contract.charge_id">View Charge
                                            Info</button>
                                    </li>
                                    <li role="menuitem">
                                        <button class="dropdown-item"
                                            (click)="moveRemoveUnpaidPop(contract)"
                                            [disabled]="!(contract.doctor_transfer_id != null && contract.status=='Won' && contract.transfer_reversal_id==null)"
                                            *ngIf="contract.move_to_unpaid == 0">Move to Unpaid</button>
                                       
                                        <button class="dropdown-item"
                                        (click)="moveRemoveUnpaidPop(contract)"
                                            [disabled]="!(contract.doctor_transfer_id != null && contract.status=='Won' && contract.transfer_reversal_id==null)"
                                            *ngIf="contract.move_to_unpaid > 0">Remove from Unpaid</button>
                                       
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>

            </table>
        </div>
        <div class="row border-top pt-2 align-items-center  w-100" *ngIf="contracts && contracts.length">
            <div class="col-md-6 col-sm-12">
                <p class="text-bold">
                    Showing {{(1+(resData.pageNumber - 1) * 10)}} to {{(contracts.length + (resData.pageNumber - 1) *
                    10)}} of {{resData.totalRecords || 'all'}}
                </p>
            </div>
            <div class="col-md-6 col-sm-12">
                <pagination class="pagination-sm float-md-end" [totalItems]="resData.totalRecords" [maxSize]="5"
                    [boundaryLinks]="true" [rotate]="false" (pageChanged)="pageChanged($event)"
                    [(ngModel)]="resData.pageNumber">
                </pagination>
            </div>

        </div>


    </div>
    <ng-template #noData>
        <div class="col-12 text-center">
            <img src="assets/images/svg/patients.svg">
            <h4 style="color:#8592a5">No Payments found</h4>
        </div>
    </ng-template>
</div>