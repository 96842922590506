import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from 'src/app/components/shared/api.service';
import { SharedService } from 'src/app/components/shared/shared.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MainService } from 'src/app/components/shared/main.service';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { CustomerService } from 'src/app/components/shared/customers.service';
import { ToasterService } from 'src/app/components/shared/toaster/toaster.service';
import { SharedModule } from 'src/app/components/shared/shared.module';

@Component({
  selector: 'app-customer-modal',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    ImageCropperComponent,
    SharedModule,
  ],
  templateUrl: './customer-modal.component.html',
  styleUrl: './customer-modal.component.scss',
})
export class CustomerModalComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  public appDisappForm!: UntypedFormGroup;
  public appAchForm!: UntypedFormGroup;
  public updateEmailForm!: UntypedFormGroup;
  public verifyBankForm!: UntypedFormGroup;
  public updateBankForm!: UntypedFormGroup;
  public addBankLinkForm!: UntypedFormGroup;
  public verifyBankLinkForm!: UntypedFormGroup;
  public addNewAddressForm!: UntypedFormGroup;
  public makeDefaultAddressForm!: UntypedFormGroup;
  public addAlternateEmailForm!: UntypedFormGroup;
  public addAlternatePhoneForm!: UntypedFormGroup;
  public bankLinkForm!: UntypedFormGroup;
  public appContractForm!: UntypedFormGroup;
  public changePaymentSettingsForm!: UntypedFormGroup;
  public reAuthEmailForm!: UntypedFormGroup;
  public fullSSNForm!: UntypedFormGroup;
  approval_code!: UntypedFormGroup;

  public modalType = '';
  public extra_data!: any;
  phone_mask: any = '000-000-0000';

  public industryForm!: UntypedFormGroup;
  public specialities!: specialities[];
  public industriesList!: industry[];

  public mask: any = [
    /[0-9]/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  approveEmailForm!: UntypedFormGroup;
  changeContractStatusForm!: UntypedFormGroup;
  phaseForm!: UntypedFormGroup;
  moneyValueForm!: UntypedFormGroup;
  customEmailForm!: UntypedFormGroup;

  patient: any = {};
  load_notes = false;
  show_full_ssn = false;
  showPwd = false;
  file: any;
  return: any;
  update: any;
  addCardNote: any;
  bank_data: any;

  addresses: any = {
    address_array: [],
    count: 0,
    add_address: {
      zipcode_list: [],
      new_address: '',
      new_mobile: '',
      new_zip: '',
      city: '',
      city_id: '',
      state: '',
      addAddressNote: '',
    },
    update_address: {
      choose_option: 0,
      selected_address_id: 0,
      updateAddressNote: '',
    },
    contracts: [],
    selected_contracts: [],
  };
  alternate_emails: any = {
    emails: [],
    count: 0,
    alternate_email: '',
    email_id: 0,
    addEmailNote: '',
  };
  alternate_phones: any = {
    phones: [],
    count: 0,
    alternate_phone: '',
    email_id: 0,
    addPhoneNote: '',
  };

  croppedImage: any;
  @ViewChild('cropper', { static: false }) cropper!: ImageCropperComponent;

  current_phase = 0;
  constructor(
    private bsModalRef: BsModalRef,
    public _shared: SharedService,
    private _api: ApiService,
    private _fb: UntypedFormBuilder,
    public mainService: MainService,
    public _customer: CustomerService,
    private _toaster: ToasterService
  ) {}

  ngOnInit() {
    
    if (this.modalType == this._customer.modalTypes.appDisappForm) {
      this.appDisappForm = this._fb.group({
        approvenote: [''],
      });
    }

    if (this.modalType == this._customer.modalTypes.appAchForm) {
      this.appAchForm = this._fb.group({
        achnote: [''],
      });
    }

    if (this.modalType == this._customer.modalTypes.updateEmailForm) {
      this.updateEmailForm = this._fb.group({
        patient_email: [this.update.patient_email],
        allContracts: [this.update.allContracts],
        contract_code: [''],
        notes: [''],
      });
    }

    if (this.modalType == this._customer.modalTypes.verifyBankForm) {
      this.verifyBankForm = this._fb.group({
        first_amount: [''],
        second_amount: [''],
        notes: [''],
      });
    }

    if (this.modalType == this._customer.modalTypes.updateBankForm) {
      this.updateBankForm = this._fb.group({
        account_type: [''],
        accountName: [''],
        accountRouting: [''],
        accountRoutingConfirm: [''],
        accountNumber: [''],
        accountNumberConfirm: [''],
        notes: [''],
      });
    }

    if (this.modalType == this._customer.modalTypes.addBankLinkForm) {
      this.addBankLinkForm = this._fb.group({
        email: [''],
        phone: [''],
        notes: [''],
      });
    }

    if (this.modalType == this._customer.modalTypes.verifyBankLinkForm) {
      this.verifyBankLinkForm = this._fb.group({});
    }

    if (this.modalType == this._customer.modalTypes.addNewAddressForm) {
      this.addNewAddressForm = this._fb.group({
        customer_address: [''],
        customer_zip: [this.patient.zip],
        customer_city: [this.patient.city],
        customer_state: [this.patient.state],
        addAddressNote: [''],
      });
    }

    if (this.modalType == this._customer.modalTypes.makeDefaultAddressForm) {
      this.makeDefaultAddressForm = this._fb.group({
        all: [''],
        selected: [''],
        no: [''],
      });
    }

    if (this.modalType == this._customer.modalTypes.addAlternateEmailForm) {
      this.addAlternateEmailForm = this._fb.group({
        alternateEmail: [''],
        addEditAlternateEmaitNote: [''],
      });
    }

    if (this.modalType == this._customer.modalTypes.addAlternatePhoneForm) {
      this.addAlternatePhoneForm = this._fb.group({
        alternatePhone: [''],
        addEditAlternatePhoneNote: [''],
      });
    }

    if (this.modalType == this._customer.modalTypes.bankLinkForm) {
      this.bankLinkForm = this._fb.group({
        phone: [''],
        notes: [''],
        achnote: [''],
      });
    }

    if (this.modalType == this._customer.modalTypes.appContractForm) {
      this.appContractForm = this._fb.group({
        achnote: [''],
      });
    }

    if (this.modalType == this._customer.modalTypes.changePaymentSettingsForm) {
      this.changePaymentSettingsForm = this._fb.group({
        show_google_pay: [this.patient.show_google_pay],
        show_apple_pay: [this.patient.show_apple_pay],
        paymentNote: [''],
      });
    }

    if (this.modalType == this._customer.modalTypes.reAuthEmailForm) {
      this.reAuthEmailForm = this._fb.group({
        send_reauth_email: [''],
        reAuth_note: [''],
      });
    }

    if (this.modalType == this._customer.modalTypes.fullSSNForm) {
      this.fullSSNForm = this._fb.group({
        password: [''],
        note: [''],
      });
    }

    if (this.modalType == this._customer.modalTypes.approval_code) {
      this.approval_code = this._fb.group({
        email: [, [Validators.pattern(this.extra_data.email_regex)]],
        mobile: [],
        request_panel_link: [],
        website_url: ['', [Validators.pattern(this.extra_data.url_regex)]],
        notes: [''],
      });
    }
  }

  focusIn(event: FocusEvent) {
    this.extra_data.is_website_input_focus = true;
  }

  focusOut(event: FocusEvent) {
    this.extra_data.is_website_input_focus = false;
  }

  resend_link() {
    if (this.approval_code.valid == false) {
      return false;
    }
    let val = this.approval_code.value;
    if (!val.email && !val.mobile) {
      this._shared.customPopups('Please enter Email/mobile to proceed', 1);
      return;
    } else if (this.extra_data.link_type == 1 && !val.website_url) {
      this._shared.customPopups('Please enter valid website url', 1);
      return;
    }
    let data1: any = {
      doctor_id: this.extra_data.leads_data.doctor_id,
      application_id: this.extra_data.leads_data.application_id,
      request_panel_link:
        this.extra_data.link_type == 2 ? val.request_panel_link : null,
      website_url: this.extra_data.link_type == 1 ? val.website_url : null,
      customer_email: val.email,
      customer_mobile: val.mobile ? val.mobile : '',
      notes: val.notes,
    };
    this._api
      .api(data1, 'resend_preapproval_link', 'post')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          if (
            this.extra_data.leads_data &&
            this.extra_data.leads_data.resend_link_count
          ) {
            this._shared.customPopups(
              'Pre-Approval code resend successfully',
              data.is_error
            );
          } else {
            this._shared.customPopups(
              'Pre-Approval code send successfully',
              data.is_error
            );
          }
          // modal.hide();
          // this.get_leads_info();
        } else if (data.is_error == 1) {
          this._shared.customPopups(data.err, 1);
          // modal.hide();
        }
        this._customer.modalTypes.approval_code = '';
      });

    return true;
  }

  // hide modal without any action
  cancel() {
    // this.passEntry.emit({ success: false });
    this.hide();
  }

  // hide modal common function
  hide() {
    this.bsModalRef.hide();
  }

  imageCropped(event: any) {
    this.croppedImage = event.base64;
  }

  approveDisapprove() {
    let { approvenote } = this.appDisappForm.value;
    approvenote = approvenote.trim();

    if (approvenote == '') {
      return this._toaster.showToaster(
        'Warning',
        `Please enter a valid note.`,
        'warning'
      );
    }

    let data = {
      patient_id: this.extra_data.patient_id,
      approve_disapprove: this.extra_data.account_approved == 1 ? 0 : 1,
      reason: approvenote,
      note: approvenote,
      action_type: this.extra_data.account_approved == 1 ? 5 : 6,
    };
    this._api.api(data, 'customer/verify_patient_email', 'post').subscribe(
      (data: any) => {
        if (data.is_error == 0) {
          this.extra_data.account_approved = this.extra_data.account_approved ? 0 : 1;
          this.load_notes = false;
          
          this.hide();
          this.passEntry.emit();
          return this._toaster.showToaster(
            'Success',
            `Customer ${this.extra_data.account_approved === 1 ? 'approved' : 'disapproved'} successfully.`,
            'success'
          );
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
    return null;
  }

  updateEmailAPI() {
    if (
      !this.updateEmailForm.value.patient_email ||
      !this.updateEmailForm.value.patient_email.trim().length
    ) {
      this._shared.customPopups('Please enter a valid email', 2);
      return false;
    }
    if (
      this.updateEmailForm.value.patient_email ===
      this.update.patient_email
    ) {
      this._shared.customPopups('Please enter a different email', 2);
      return false;
    }
    if (!this.updateEmailForm.value.allContracts) {
      if (
        !this.updateEmailForm.value.contract_code ||
        !this.updateEmailForm.value.contract_code.trim().length
      ) {
        this._shared.customPopups('Please enter a valid contract code', 2);
        return false;
      }
    }
    let data = {
      new_patient_email: this.updateEmailForm.value.patient_email,
      old_patient_email: this.update.patient_email,
      action_type: 16,
      note: this.updateEmailForm.value.notes,
      contract_code : this.updateEmailForm.value.contract_code
    };
    if (this.updateEmailForm.value.allContracts) {
      delete data['contract_code'] 
    }
    this._api.api(data, 'customer/edit_patient_email', 'post').subscribe(
      (data: any) => {
        if (data.is_error == 0) {
          this.patient.patient_email = this.update.patient_email;
          this.load_notes = false;
         this.hide()
         this.passEntry.emit();
          this.updateEmailForm.reset();
        } else {
          if (data.original_text || data.err) {
            this._shared.customPopups(
              data.original_text || data.err,
              data.is_error
            );
          } else {
            this._shared.customPopups(data.is_error, data.flag);
          }
        }
      },
      (error: any) => {
        console.log(error);
      }
    );

    return null;
  }

  verifyBankData() {
    // console.log('form--', form, this.bank_data);

    if (
      !this.bank_data.first_amount ||
      this.bank_data.first_amount.trim().length == 0
    ) {
      this._shared.customPopups('Please Enter the First Amount', 1);
      return false;
    }
    if (
      !this.bank_data.second_amount ||
      this.bank_data.second_amount.trim().length == 0
    ) {
      this._shared.customPopups('Please Enter the Second Amount.', 1);
      return false;
    }
    if (
      !this.verifyBankForm.value.notes ||
      !this.verifyBankForm.value.notes.trim().length
    ) {
      this._shared.customPopups('Please enter a valid note.', 1);
      return false;
    }
    let params = {
      bank_id: this.bank_data.token,
      first_amount: parseInt(this.bank_data.first_amount),
      second_amount: parseInt(this.bank_data.second_amount),
      patient_id: this.patient.patient_id,
      action_type: 45,
      note: this.verifyBankForm.value.notes,
    };

    this._api.api(params, 'customer/verify_customer', 'post').subscribe(
      (data: any) => {
        if (data.is_error == 0) {
          this._shared.customPopups('Bank Verified Successfully', 0);
          this._customer.modalTypes.verifyBankForm = '';
          // modal.hide();
          // form.reset();
          return this.patient.patient_id;
        } else {
          this._shared.customPopups(data.err, 1);
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
    return null;
  }

  enableDisableAch() {
    if (
      this.appAchForm.value.achnote &&
      !this.appAchForm.value.achnote.trim().length
    ) {
      this._shared.customPopups('Please enter a valid note.', 2);
      return false;
    }
    // console.log('Extra Data::', this.extra_data);
    let data = {
      patient_id: this.extra_data.patient_id,
      can_add_bank: this.extra_data.show_ach == 1 ? 0 : 1,
      note: this.appAchForm.value.achnote,
      action_type: this.extra_data.show_ach == 1 ? "disable_ach" : "enable_ach",
    };
    this._api.api(data, 'customer/toggle_ach_bank', 'post').subscribe(
      (data: any) => {
        if (data.is_error == 0) {
          this._toaster.showToaster(
            'success',
            `ACH ${this.extra_data.show_ach ? 'Disable' : 'Enable'} successfully.`,
            'success'
          );
          this.hide();
          this.passEntry.emit();
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
    return null;
  }

  addStripeBank() {
    // console.log('form--', this.updateBankForm);
    if (
      this.updateBankForm.value.accountRouting !=
      this.updateBankForm.value.accountRoutingConfirm
    ) {
      this._shared.customPopups("Routing numbers don't match", 1);
      return false;
    }
    if (
      !this.updateBankForm.value.account_type ||
      this.updateBankForm.value.account_type == 0
    ) {
      this._shared.customPopups('Choose an account type', 1);
      return false;
    }
    if (
      this.updateBankForm.value.accountNumber !=
      this.updateBankForm.value.accountNumberConfirm
    ) {
      this._shared.customPopups("Account numbers don't match", 1);
      return false;
    }
    if (
      !this.updateBankForm.value.notes ||
      !this.updateBankForm.value.notes.trim().length
    ) {
      this._shared.customPopups('Please enter a valid note.', 1);
      return false;
    }
    var data = {
      country: 'US',
      currency: 'USD',
      routing_number: this.updateBankForm.value.accountRoutingConfirm,
      account_holder_name: this.updateBankForm.value.accountName,
      account_number: this.updateBankForm.value.accountNumber,
      account_holder_type:
        this.updateBankForm.controls['account_type'].value == 1
          ? 'individual'
          : 'company',
    };
    // TODO: Need to implement stripe
    // this._shared.stripe
    //   .createToken('bank_account', data)
    //   .then((response) => {
    //     if (response && response.error) {
    //       if (response.error.param == 'bank_account[account_number]') {
    //         this._shared.customPopups('Invalid Account Number', 1);
    //       } else if (response.error.param == 'bank_account[routing_number]') {
    //         this._shared.customPopups('Invalid Routing Number', 1);
    //       }
    //       return false;
    //     } else {
    //       this.stripeResponseHandler(response.token, form, modal);
    //     }
    //   });

    return null;
  }

  addBankLinkFn() {
    // const mobile = this.extra_data.user_phone
    //   ? this.extra_data.user_phone.replace(/-/g, '')
    //   : this.patient['patient_mobile']
    //   ? this.patient['patient_mobile'].split('-')[1]
    //   : '';
    // if (mobile && mobile.length < 9) {
    //   this._toaster.showToaster(
    //     'warning',
    //     'Please enter valid phone number',
    //     'warning'
    //   );
    //   return false;
    // }
    // if (!this.extra_data.user_email) {
    //   this._toaster.showToaster(
    //     'warning',
    //     'Please enter valid email',
    //     'warning'
    //   );
    //   return false;
    // }
    // if (!this.extra_data.user_phone) {
    //   this._toaster.showToaster(
    //     'warning',
    //     'Please enter valid phone number',
    //     'warning'
    //   );
    //   return false;
    // }
    if (
      !this.addBankLinkForm.value.notes ||
      !this.addBankLinkForm.value.notes.trim().length
    ) {
      this._shared.customPopups('Please enter a valid note.', 1);
      return false;
    }
    const data = {
      patient_id: this.extra_data.patient_id,
      email: this.addBankLinkForm.value.email,
      mobile: this.addBankLinkForm.value.phone,
      action_type: "add_bank_link_form",
      note: this.addBankLinkForm.value.notes,
      mobile_country_code: this.extra_data.patient_mobile_country_code,
    };
    this._api.api(data, 'customer/send_add_bank_link', 'post').subscribe(
      (data: any) => {
        if (data && data.is_error == 0) {
            this._toaster.showToaster(
              'success',
              'Link has been sent successfully.',
              'success'
            );
          this.hide();
          //this.passEntry.emit(this.extra_data.id)
        }
        
      },
      (error: any) => {
        console.log(error);
      }
    );
    return null;
  }

  verifyBankLinkFn() {
    let mobile = this.extra_data.verify_link_phone
      ? this.extra_data.verify_link_phone.replace(/-/g, '')
      : this.patient['patient_mobile']
      ? this.patient['patient_mobile'].split('-')[1]
      : '';
    if (mobile && mobile.length < 9) {
      this._toaster.showToaster(
        'warning',
        'Please enter valid phone number',
        'warning'
      );
      return false;
    }
    if (!this.extra_data.verify_link_email) {
      this._toaster.showToaster(
        'warning',
        'Please enter valid email',
        'warning'
      );
      return false;
    }
    if (!this.extra_data.verify_link_phone) {
      this._toaster.showToaster(
        'warning',
        'Please enter valid phone number',
        'warning'
      );
      return false;
    }
    if (
      !this.verifyBankLinkForm.value.notes ||
      !this.verifyBankLinkForm.value.notes.trim().length
    ) {
      this._shared.customPopups('Please enter a valid note.', 1);
      return false;
    }
    const data = {
      patient_id: this.patient['patient_id'],
      email: this.extra_data.verify_link_email,
      mobile: mobile,
      action_type: 43,
      note: this.extra_data.value.notes,
      //plaid_id: this.plaid_accounts[0]['id'],
    };
    this._api.api(data, 'customer/resend_micro_deposit_link', 'post').subscribe(
      (data: any) => {
        if (data && data.is_error == 0) {
          if (data.flag == 552) {
            this._shared.customPopups(data.data, 0);
          } else {
            this._toaster.showToaster(
              'success',
              'Link has been sent successfully.',
              'success'
            );
          }
          this._customer.modalTypes.verifyBankLinkForm = '';
          // modal.hide();
          // form.reset();
          //this.getPatientInfo(this.patient.patient_id);
          return this.patient.patient_id;
        }
        if (data.is_error == 1) {
          if (data.override_text) {
            this._shared.customPopups(data.override_text, data.is_error);
          } else {
            this._shared.customPopups(data.is_error, data.flag);
          }
        }
      },
      (error: any) => {
        console.log(error);
      }
    );

    return null;
  }

  makeDefaultAddress() {
    const params = {
      patient_id: this.extra_data.id,
      address_id: this.extra_data.addresses.update_address.selected_address_id,
      contract_ids: '',
      for_all_contracts: 0,
      note: this.extra_data.addresses.update_address.updateAddressNote,
    };
    if (
      !this.extra_data.addresses.update_address.choose_option ||
      this.extra_data.addresses.update_address.choose_option == 0
    ) {
      this._shared.customPopups('Please Choose an appropriate option', 2);
      return false;
    } else if (this.extra_data.addresses.update_address.choose_option == 1) {
      params.for_all_contracts = 1;
    } else if (this.extra_data.addresses.update_address.choose_option == 2) {
      if (this.extra_data.addresses.selected_contracts.length == 0) {
        this._shared.customPopups('Please Choose atleast one contract', 2);
        return false;
      }
      params.contract_ids =
        this.extra_data.addresses.selected_contracts.toString();
    }
    if (
      !this.extra_data.addresses.update_address.updateAddressNote ||
      this.extra_data.addresses.update_address.updateAddressNote.trim() == ''
    ) {
      this._toaster.showToaster(
        'warning',
        'Please enter valid notes',
        'warning'
      );
      return false;
    }
    this._api
      .api(params, 'customer/make_patient_address_default', 'post')
      .subscribe(
        (data: any) => {
          if (data && data.is_error == 0) {
            if (this.extra_data.addresses.update_address.choose_option == 1) {
              this._shared.customPopups(
                'Customer Address has been Changed for all Contracts.',
                0
              );
            } else if (
              this.extra_data.addresses.update_address.choose_option == 2
            ) {
              this._shared.customPopups(
                'Customer Address has been changed for selected Contracts.',
                0
              );
            } else {
              this._shared.customPopups(
                'Customer Address has been changed for Profile.',
                0
              );
            }
            // modal.hide();
            // form.reset();
            this._customer.modalTypes.makeDefaultAddressForm = '';

            // this.getPatientInfo(this.id);
            this.fetchCustomerAddressDetails(this.extra_data.id);
          }
          if (data.is_error == 1) {
            if (data.override_text || data.err) {
              this._shared.customPopups(
                data.override_text || data.err,
                data.is_error
              );
            } else {
              this._shared.customPopups(data.is_error, data.flag);
            }
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
    return null;
  }

  fetchCustomerAddressDetails(id: any) {
    const params = {
      patient_id: id,
    };
    this._api.api(params, 'customer/get_patient_address', 'post').subscribe(
      (data: any) => {
        if (data && data.is_error == 0) {
          this.extra_data.addresses.address_array = data.address;
          this.extra_data.addresses.count = data.count;
        }
        if (data.is_error == 1) {
          if (data.override_text) {
            this._shared.customPopups(data.override_text, data.is_error);
          } else {
            this._shared.customPopups(data.is_error, data.flag);
          }
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  selectedContracts(contract_id: number) {
    if (contract_id) {
      let temp = this.addresses.selected_contracts.find(
        (value: number) => value === contract_id
      );
      if (!temp) {
        this.addresses.selected_contracts.push(contract_id);
      } else {
        this.addresses.selected_contracts.forEach(
          (item: number, index: number) => {
            if (item === contract_id) {
              this.addresses.selected_contracts.splice(index, 1);
            }
          }
        );
      }
    }
  }

  addAlternateEmail() {
    const params = {
      patient_id: this.extra_data.patient_id,
      alternate_email: this.addAlternateEmailForm.value.alternateEmail,
      note: this.addAlternateEmailForm.value.addEditAlternateEmaitNote,
    };
    if (
      !this.addAlternateEmailForm.value.alternateEmail ||
      this.addAlternateEmailForm.value.alternateEmail.trim() == ''
    ) {
      this._toaster.showToaster(
        'warning',
        'Please enter valid Email Address',
        'warning'
      );
      return false;
    }
    if (
      !this.addAlternateEmailForm.value.addEditAlternateEmaitNote ||
      this.addAlternateEmailForm.value.addEditAlternateEmaitNote.trim() == ''
    ) {
      this._toaster.showToaster(
        'warning',
        'Please enter valid notes',
        'warning'
      );
      return false;
    }

    this._api
      .api(params, 'customer/add_patient_alternate_email', 'post')
      .subscribe(
        (data: any) => {
          if (data && data.is_error == 0) {
            this.hide();
            this.passEntry.emit();
            return this._toaster.showToaster(
              'success',
              'New Alternate Email added successfully.',
              'success'
            );
            
          }
        },
        (error: any) => {
          console.log(error);
        }
      );

    return null;
  }

  fetchAlternateEmails(id: any) {
    const params = { patient_id: id };
    this._api
      .api(params, 'customer/patient_alternate_emails_list', 'post')
      .subscribe(
        (data: any) => {
          if (data && data.is_error == 0) {
            this.alternate_emails.emails = data.alternate_emails;
            this.alternate_emails.count = data.count;
          }
          if (data.is_error == 1) {
            if (data.override_text || data.err) {
              this._shared.customPopups(
                data.override_text || data.err,
                data.is_error
              );
            } else {
              this._shared.customPopups(data.is_error, data.flag);
            }
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  addAlternateMobile() {
    let mobile = '';
    if (this.addAlternatePhoneForm && this.addAlternatePhoneForm.value) {
      mobile = this.addAlternatePhoneForm.value.alternatePhone
        ? this.addAlternatePhoneForm.value.alternatePhone.replace(/-/g, '')
        : '';
      if (
        !this.addAlternatePhoneForm.value.alternatePhone ||
        this.addAlternatePhoneForm.value.alternatePhone.trim() == ''
      ) {
        this._toaster.showToaster(
          'warning',
          'Please enter valid Phone Number',
          'warning'
        );
        return false;
      }
      if (
        !this.addAlternatePhoneForm.value.addEditAlternatePhoneNote ||
        this.addAlternatePhoneForm.value.addEditAlternatePhoneNote.trim() == ''
      ) {
        this._toaster.showToaster(
          'warning',
          'Please enter valid notes',
          'warning'
        );
        return false;
      }
    }
    const params = {
      patient_id: this.extra_data.patient_id,
      alternate_mobile_country_code:
        this.extra_data.patient_mobile_country_code,
      alternate_mobile: mobile,
      note: this.addAlternatePhoneForm.value.addEditAlternatePhoneNote,
    };
    this._api
      .api(params, 'customer/add_patient_alternate_mobile', 'post')
      .subscribe(
        (data: any) => {
          // console.log(data);
          if (data && data.is_error == 0) {                        
            this.hide();
            this.passEntry.emit({added: true});
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
    return null;
  }

  getZipselected(event: any, profile: any) {
    // console.log(event);
    this.addNewAddressForm.patchValue({
      customer_state: event.state_name,
      customer_city: event.city,
      customer_zip: event.zip,
      customer_address: event && event.item && event.item['name'],
    });
  }

  addNewAddress() {
    if (this.addNewAddressForm && this.addNewAddressForm.value) {
      if (
        !this.addNewAddressForm.value.customer_address ||
        this.addNewAddressForm.value.customer_address.trim() == ''
      ) {
        this._toaster.showToaster(
          'warning',
          'Please enter valid Address',
          'warning'
        );
        return false;
      }
      if (
        !this.addNewAddressForm.value.addAddressNote ||
        this.addNewAddressForm.value.addAddressNote.trim() == ''
      ) {
        this._toaster.showToaster(
          'warning',
          'Please enter valid notes',
          'warning'
        );
        return false;
      }
    }
    const data = {
      patient_id: this.patient['patient_id'],
      city: this.addNewAddressForm.value.customer_city,
      state: this.addNewAddressForm.value.customer_state,
      address: this.addNewAddressForm.value.customer_address.trim(),
      note: this.addNewAddressForm.value.addAddressNote,
      zip_code: this.addNewAddressForm.value.customer_zip,
    };
    this._api.api(data, 'customer/add_customer_address', 'post').subscribe(
      (data: any) => {
        if (data && data.is_error == 0) {
          
          this.hide();
          this.passEntry.emit(this.extra_data.id)
          return this._toaster.showToaster(
            'Success',
            'Address has been added Successfully.',
            'success'
          );
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
    return null;
  }

  fetchAlternatePhones(id: any) {
    const params = { patient_id: id };
    this._api
      .api(params, 'customer/patient_alternate_mobiles_list', 'post')
      .subscribe(
        (data: any) => {
          if (data && data.is_error == 0) {
            this.alternate_phones.phones = data.alternate_mobiles;
            this.alternate_phones.phones.forEach((o: any) => {
              o.alternate_mobile =
                o.alternate_mobile.slice(0, 6) +
                '-' +
                o.alternate_mobile.slice(6, 9) +
                '-' +
                o.alternate_mobile.slice(9, 13);
            });
            this.alternate_phones.count = data.count;
          }
          if (data.is_error == 1) {
            if (data.override_text || data.err) {
              this._shared.customPopups(
                data.override_text || data.err,
                data.is_error
              );
            } else {
              this._shared.customPopups(data.is_error, data.flag);
            }
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  enableDisableContractCreation() {
    if (
      this.appContractForm.value.achnote &&
      !this.appContractForm.value.achnote.trim().length
    ) {
      this._shared.customPopups('Please enter a valid note.', 2);
      return false;
    }
    let data = {
      patient_id: this.patient.patient_id,
      can_activate_contract: this.patient.can_activate_contract == 1 ? 0 : 1,
      note: this.appContractForm.value.achnote,
      action_type:
        this.patient.can_activate_contract == 1
          ? 'enable_contract_creation'
          : 'disabled_contract_creation',
    };
    this._api.api(data, 'customer/toggle_activate_contract', 'post').subscribe(
      (data: any) => {
        if (data.is_error == 0) {
          this.load_notes = false;
          this._customer.modalTypes.appContractForm;
          // return this.patient.patient_id;
          this.hide();
          this.passEntry.emit({success: true});
          this._shared.customPopups('Changes made successfully!', 3);
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
    return null;
  }

  changeCustomerPaymentSettings() {
    if (
      this.changePaymentSettingsForm.value.paymentNote &&
      !this.changePaymentSettingsForm.value.paymentNote.trim().length
    ) {
      this._shared.customPopups('Please enter a valid note.', 2);
      return false;
    }
    let params = {
      patient_id: this.patient.patient_id,
      show_google_pay: this.changePaymentSettingsForm.value.show_google_pay
        ? 1
        : 0,
      show_apple_pay: this.changePaymentSettingsForm.value.show_apple_pay
        ? 1
        : 0,
      note: this.changePaymentSettingsForm.value.paymentNote,
    };
    this._api
      .api(params, 'customer/edit_customer_payment_settings', 'post')
      .subscribe(
        (data: any) => {
          if (data.is_error == 0) {
            this.hide();
            this.passEntry.emit(this.patient.patient_id)
            this._shared.customPopups('Settings changed successfully', 0);
            // this.getPatientInfo(this.patient.patient_id);
            return 
          }
        },
        (error: any) => {
          console.log(error);
        }
      );

    return null;
  }

  // reAuthEmailSettings() {
  //   if (!this.reAuthEmailForm.value.reAuth_note) {
  //     this._shared.customPopups('Please enter a valid note.', 2);
  //     return false;
  //   }
  //   let params = {
  //     patient_id: this.patient.patient_id,
  //     send_reauth_email: this.extra_data.send_reauth_email ? 1 : 0,
  //     note: this.reAuthEmailForm.value.reAuth_note,
  //   };
  //   this._api
  //     .api(params, 'customer/send_reauth_email_toggle', 'post')
  //     .subscribe(
  //       (data: any) => {
  //         if (data.flag == 209) {
  //           // this.getPatientInfo(this.patient.patient_id);
  //           this._shared.customPopups('Status updated successfully.', 0);
  //           return this.patient.patient_id;
  //           // modal.hide();
  //           // form.reset();
  //         } else {
  //           this._shared.customPopups(data.err, 1);
  //         }
  //       },
  //       (error: any) => {
  //         console.log(error);
  //       }
  //     );
  //   return null;
  // }

  reAuthBankLinkFn() {
    console.log('this.verify_link_email--', this.extra_data.reauth_link_email);
    let mobile = this.extra_data.reauth_link_phone
      ? this.extra_data.reauth_link_phone.replace(/-/g, '')
      : this.patient['patient_mobile']
      ? this.patient['patient_mobile'].split('-')[1]
      : '';
    if (mobile && mobile.length < 9) {
      this._toaster.showToaster(
        'warning',
        'Please enter valid phone number',
        'warning'
      );
      return false;
    }
    if (!this.extra_data.reauth_link_email) {
      this._toaster.showToaster(
        'warning',
        'Please enter valid email',
        'warning'
      );
      return false;
    }
    if (!this.extra_data.reauth_link_phone) {
      this._toaster.showToaster(
        'warning',
        'Please enter valid phone number',
        'warning'
      );
      return false;
    }
    if (
      !this.bankLinkForm.value.notes ||
      !this.bankLinkForm.value.notes.trim().length
    ) {
      this._shared.customPopups('Please enter a valid note.', 1);
      return false;
    }
    const data = {
      patient_id: this.patient['patient_id'],
      email: this.extra_data.reauth_link_email,
      mobile: mobile,
      action_type: 56,
      note: this.bankLinkForm.value.notes,
    };
    this._api.api(data, 'customer/reauth_plaid_link', 'post').subscribe(
      (data: any) => {
        if (data && data.is_error == 0) {
          if (data.flag == 552) {
            this._shared.customPopups(data.data, 0);
          } else {
            this._toaster.showToaster(
              'success',
              'Link has been sent successfully.',
              'success'
            );
          }
          // modal.hide();
          // form.reset();
          this._customer.modalTypes.bankLinkForm = '';
          return this.patient.patient_id;
        }
        if (data.is_error == 1) {
          if (data.override_text) {
            this._shared.customPopups(data.override_text, data.is_error);
          } else {
            this._shared.customPopups(data.is_error, data.flag);
          }
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
    return null;
  }

  verifyFullSSN() {
    const { note, password} = this.fullSSNForm.value;

    if (password == '') {
      this._toaster.showToaster(
        'warning',
        `Please enter a valid password}`,
        'warning'
      );
      return false;
    } 
    if (note === '') {
      this._toaster.showToaster(
        'warning',
        `Please enter a valid note}`,
        'warning'
      );
      return false;
    }    

    const params = {
      patient_id: this.extra_data.patient_id,
      admin_password: this._shared.crypto(password),
      note: note,
    };

    this._api
      .api(params, 'customer/view_customer_complete_ssn', 'post')
      .subscribe(
        (data: any) => {
          if (data.is_error == 0) {
            // return this._toaster.showToaster(
            //   'success',
            //   'Please enter a valid note',
            //   'success'
            // );
            // return true;
            this.hide();
            this.passEntry.emit(this.patient.patient_id)
          }
        },
        (error: any) => {
          console.log(error);
          return this._toaster.showToaster(
            'warning',
            error || 'Something went wrong',
            'warning'
          );
        }
      );

    return null;
  }
}

interface industry {
  ind_id: number;
  ind_name: string;
}

interface specialities {
  sub_ind_id: number;
  sub_ind_name: string;
  doctor_speciality: string;
  speciality_name: string;
}
