<div class="p-3">
  <app-contract-tabs [counts]="counts"></app-contract-tabs>

  <div class="row m-0 align-items-center mb-2" [hidden]="!show_filters">
    <app-filters class="row m-0 w-100" [filters]="allContractFilters" (finalValues)="filterValues($event)"
      (resetAllFilters)="resetFilters($event)">
    </app-filters>
  </div>

  <div class="card card-body m-0">

    <div class="d-flex align-self-end">
      <button (click)="showFilter(!show_filters)" type="button"
        *ngIf="!_shared.routerAuth.restricted_buttons.export && contractsType!=8 && contracts?.length"
        class="btn btn btn-primary rounded-pill py-1 px-2 me-3"
        [ngClass]="{'btn-primary':show_filters, 'btn-outline': !show_filters}">
        <span class="fs-16 me-1">{{show_filters ? "Hide Filters" : "Show Filters"}}</span>
        <i class="fa fa-filter"></i>
      </button>
      <button type="button" class="btn btn-primary rounded-pill py-1 px-2" tappable
        *ngIf="contracts && contracts.length" (click)="exportContractsModal.show()">
        Export
      </button>
    </div>

    <div class="table-responsive p-3 w-100 overflow-auto">
      <table class="table" *ngIf="contracts && contracts.length; else noData">
        <thead class="">
          <tr>
            <th class="fs-14 fw-400">S No.</th>
            <th class="fs-14 fw-400">Contract Code</th>
            <th class="fs-14 fw-400">Contract Status</th>
            <th class="fs-14 fw-400">Customer</th>
            <th class="fs-14 fw-400">Finance Details</th>
            <th class="fs-14 fw-400">Enrollment Date</th>
            <th class="fs-14 fw-400" *ngIf="contractsType==3">Cancelation Date</th>
            <th class="fs-14 fw-400" *ngIf="contractsType==4">Completion Date</th>
            <th class="fs-14 fw-400">Business Owner</th>
            <th class="fs-14 fw-400">Last Action Details</th>
            <th class="fs-14 fw-400" *ngIf="contractsType!=8">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let contract of contracts; let i=index" [class.bg-light]="contract.selected == true">
            <td class="fs-14 fw-400">{{((resData.pageNumber-1)*10)+(i + 1)}}</td>
            <td class="contract_popover">
              <a class="padd_r5" [routerLink]="'/app/contracts/details/'+contract.contract_id" [popover]="popTemplate"
                triggers="mouseenter:mouseleave" [popoverTitle]="contract.contract_code" placement="right">
                {{contract.contract_code}}
              </a>
              <br />
              <!-- <span class="radius-8 badge bg-warning" *ngIf="contract.contract_type == 1">10% Platform Fee</span> -->
              <span class="radius-8 badge bg-success fs-12 fw-400" *ngIf="contract.contract_type == 2">CredeeFi</span>
              <span class="radius-8 badge bg-primary fs-12 fw-400" *ngIf="contract.contract_type == 3">KeepeeFi</span>
              <span class="radius-8 badge bg-primary fs-12 fw-400"
                *ngIf="contract.contract_type == 4">{{contract.ez_plan_new ? 'ZeroFi Financing':
                'SimpleeFi Financing'}}</span>
              <span class="radius-8 badge bg-primary fs-12 fw-400" *ngIf="contract.contract_type == 6">SimpleeFi (With
                Deferred Interest)</span>
              <span class="radius-8 badge bg-primary fs-12 fw-400" *ngIf="contract.contract_type == 5">Debt Recovery
                Payment Plan</span> <br>
              <span class="radius-8 badge"
                [ngClass]="{'bg-success': contract.is_contract_verified == 1, 'bg-danger':contract.is_contract_verified != 1}">
                {{contract.is_contract_verified == 1 ? 'Verified': 'Non-verified'}}
              </span>
              <p *ngIf="contract.bankrupt_stop_payment == 1" class="m-0">
                <span class="radius-8 badge bg-danger fs-12 fw-400">{{contract.bankrupt_stop_payment == 1 ?'Bankruptcy
                  Filed':''}}</span>
              </p>
              <p *ngIf="contract.dnd == 1" class="m-0">
                <span class="radius-8 badge bg-dange fs-12 fw-400"> Do not Disturb</span>
              </p>
              <p *ngIf="contract.dispute_payment_status == 1" class="m-0">
                <span class="radius-8 badge bg-danger fs-12 fw-400"> Payment/Service Disputed</span>
              </p>
              <ng-template #popTemplate>
                <app-hover-contract [content]="contract"></app-hover-contract>
              </ng-template>
            </td>
            <td class="fs-14 fw-400">
              {{contract.pf_status ? ((contract.pf_status==1||contract.pf_status==2) ? 'Active':
              contract.pf_status==3 ? 'Overdue' : contract.pf_status==4 ? 'Completed' : contract.pf_status==5 ?
              'Canceled':
              '-') :'-'}}
            </td>
            <td>
              <span *ngIf="contract.patient_first_name || contract.patient_last_name;else no_name">
                <a class="padd_r5 decoration-none fs-14 fw-400">
                  {{(contract.title?contract.title+' ':'')+(contract.patient_first_name !=
                  null?contract.patient_first_name:'-')+' '+(contract.patient_last_name !=
                  null?contract.patient_last_name:"-")}}
                </a>
              </span>
              <ng-template #no_name>-</ng-template>
              <br>
              <span class="a break-text fs-14 fw-400"
                *ngIf="(!_shared.routerAuth.restricted_buttons.show_contact_in_dnd && contract.dnd == 1) || contract.dnd == 0">{{contract.patient_email}}</span>
              <span class="b break-text fs-14 fw-400"
                *ngIf="(_shared.routerAuth.restricted_buttons.show_contact_in_dnd && contract.dnd == 1)">{{contract.dnd_patient_email}}</span>
            </td>
            <td>
              <span class="fs-14 fw-400">Financed Amount: {{contract.financed_amount}}</span><br>
              <span class="fs-14 fw-400">Downpayment Amount: {{contract.downpayment_amount }}</span>
            </td>
            <td class="Downpayment fs-14 fw-400">{{contract.date_added }}</td>
            <td *ngIf="contractsType==3">
              {{contract.cancelled_contract_date?(contract.cancelled_contract_date): '-'}}</td>
            <td *ngIf="contractsType==4">
              {{contract.cancelled_contract_date?(contract.cancelled_contract_date): '-'}}</td>
            <td>
              <span *ngIf="contract.doctor_name;else no_name">
                <a class="padd_r5 decoration-none fs-14 fw-400">
                  {{contract.doctor_name}}
                </a>
              </span>
              <ng-template #no_name>-</ng-template>
              <br>
              <span class="c break-text fs-14 fw-400"
                *ngIf="(!_shared.routerAuth.restricted_buttons.show_contact_in_dnd && contract.doctor_dnd == 1) || contract.doctor_dnd == 0">{{contract.doctor_email}}</span>
              <span class="d break-text fs-14 fw-400"
                *ngIf="(_shared.routerAuth.restricted_buttons.show_contact_in_dnd && contract.doctor_dnd == 1)"
                class="">{{contract.dnd_doctor_email}}</span>
            </td>
            <td style="min-width: 300px;" class="fs-14 fw-400">
              Action Name: {{contract.last_action ? contract.last_action : '-'}} <br>
              Admin Name: {{contract.last_action_admin_name ? contract.last_action_admin_name : '-'}} <br>
              Last Action Date:
              {{contract.last_action_added?(contract.last_action_added):'-'}}
            </td>
            <td style="min-width: 100px;"
              *ngIf="contractsType!=8 && (contract.pf_status==1 || contract.pf_status==2 || contract.pf_status==3) && contract.bankrupt_stop_payment != 1">
              <a *ngIf="(contract.pf_status==1 || contract.pf_status==2 || contract.pf_status==3) && (!_shared.routerAuth.restricted_buttons.edit_contract && !contract.settlement_amount)"
                tooltip="Edit Financial Details" class="padd_r5 fa-text-info ml-2"
                (click)="OpenEditContractPopup(contract)">
                <i class="fa fa-pencil "></i>
              </a>
              <a *ngIf="contract.pf_status==3 && contract.legal_status==0" tooltip="Make Legal"
                class="padd_r5 fa-text-info ml-2" (click)="makeLegal(contract)">
                <i class="fa fa-gavel "></i>
              </a>
              <a *ngIf="contract.pf_status==3 && contract.legal_status==1" tooltip="Make Overdue"
                class="padd_r5 fa-text-info ml-2" (click)="makeOverdue(contract)">
                <i class="fa fa-hourglass-end "></i>
              </a>
              <a *ngIf="contract.pf_status==3" tooltip="Generate Link To Add Card" class="padd_r5 fa-text-info ml-2">
                <i class="fa fa-external-link "></i>
              </a>
            </td>
            <td style="min-width: 100px;"
              *ngIf="contract.pf_status==4 || contract.pf_status==5 || contract.bankrupt_stop_payment == 1" class="p-4">
              -
            </td>
          </tr>
        </tbody>
      </table>


      <div class="row border-top pt-2 align-items-center" *ngIf="contracts && contracts.length">
        <div class="col-md-6 col-sm-12">
          <p class="text-bold">
            Showing {{(1+(resData.pageNumber - 1) * 10)}} to {{(contracts.length + (resData.pageNumber - 1)
            *
            10)}} of {{resData.totalRecords || 'all'}}
          </p>
        </div>
        <div class="col-md-6 col-sm-12">
          <pagination class="pagination-sm float-md-end" [totalItems]="resData.totalRecords" [maxSize]="5"
            [boundaryLinks]="true" [rotate]="false" (pageChanged)="pageChanged($event)"
            [(ngModel)]="resData.pageNumber">
          </pagination>
        </div>

      </div>
    </div>
  </div>

  <ng-template #noData>
    <div class="col-12 text-center" *ngIf="!loader">
      <img src="assets/images/svg/patients.svg">
      <h4 style="color:#8592a5">No Contracts Found</h4>
    </div>
  </ng-template>

  <!-- Generate Add Card Link -->
</div>