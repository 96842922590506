<!----Approve-Disapprove form-->
<ng-container *ngIf="modalType == _customer.modalTypes.appDisappForm">
  <div class="modal-dialog modal-md allowed-actions-modal">
    <div class="modal-content border-0">
      <form
        [formGroup]="appDisappForm"
        (ngSubmit)="approveDisapprove()"
      >
        <div class="modal-header d-flex justify-content-between align-items-center bg-primary">
          <h4 class="modal-title fs-18 text-white">
            {{ extra_data.account_approved ? "Disapprove" : "Approve" }}
          </h4>
          <img src="assets/images/icons/white-cross.svg" alt="" class="cursor hw-24" (click)="cancel()">
        
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label
              >Are you sure you want to
              {{ extra_data.account_approved ? "disapprove" : "approve" }} this
              Customer?</label
            >
            <p class="text-danger" *ngIf="patient.account_approved == 1">
              If you Disapprove now, Customer will also get blocked.
            </p>
            <div class="clearfix"></div>
            <label>Reason/Note:</label>
            <textarea
              class="input_field"
              required
              formControlName="approvenote"
              cols="30"
              rows="4"
            ></textarea>
            <p
              class="text-danger"
              *ngIf="
                appDisappForm.controls['approvenote'].touched ||
                (appDisappForm.controls['approvenote'].dirty && appDisappForm.controls['approvenote'].errors)
              "
            >
              Please enter note.
            </p>
          </div>
        </div>
        <div class="modal-footer text-right">
          <div>
            <button
              type="button"
              class="btn btn-outline-secondary mr-4"
              (click)="cancel()"
            >
              Cancel
            </button>
            <button type="submit" class="btn btn-primary">
              {{ extra_data.account_approved ? "Disapprove" : "Approve" }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<!----Update email form-->
<ng-container *ngIf="modalType == _customer.modalTypes.updateEmailForm">
  <div class="modal-dialog modal-md allowed-actions-modal">
    <div class="modal-content border-0">
      <form [formGroup]="updateEmailForm" (ngSubmit)="updateEmailAPI()">
        <div class="modal-header d-flex justify-content-between align-items-center bg-primary">
          <h4 class="modal-title fs-18 text-white">Update Email</h4>
           <img src="assets/images/icons/white-cross.svg" alt="" class="cursor hw-24" (click)="cancel()">
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label>Enter new email address*</label>
            <input
              type="email"
              email
              formControlName="patient_email"
              class="input_field"
            />
          </div>

          <div class="form-group  d-flex align-items-center justify-content-between">
            <p class="float-left">Change email for all contracts:</p>
            <div class="float-right">
              <label class="switch">
                <input type="checkbox" formControlName="allContracts" />
                <span></span>
              </label>
            </div>
          </div>
          <div class="form-group " *ngIf="!updateEmailForm.controls['allContracts'].value">
            <label>Enter Contract Code</label>
            <input
              type="text"
              formControlName="contract_code"
              class="input_field"
            />
          </div>
          <div class="col-12 pl-0 pr-0">
            <div class="form-group">
              <label>Notes*</label>
              <textarea
                required
                formControlName="notes"
                class="input_field"
                trim="true"
              ></textarea>
              <p
                class="text-danger"
                *ngIf="
                  updateEmailForm.controls['notes'].touched && updateEmailForm.controls['notes'].dirty
                "
              >
                Please enter note.
              </p>
            </div>
          </div>
          <div class="clearfix">
            <small
              >Note: Email will be updated in Credee's database & Stripe</small
            >
          </div>
        </div>
        <div class="modal-footer">
          <div class="d-flex">
            <div class="ml-auto">
              <button
                type="button"
                class="btn btn-outline-secondary me-3"
                (click)="cancel()"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Confirm</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<!--Add Card Patient-->
<ng-container *ngIf="modalType == _customer.modalTypes.addCard">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content border-0">
      <div class="modal-header d-flex justify-content-between align-items-center bg-primary">
        <h4 class="modal-title fs-18 text-white">Add Card</h4>
        <img src="assets/images/icons/white-cross.svg" alt="" class="cursor hw-24" (click)="cancel()">
      </div>
      <div class="modal-body">
        <div class="clearfix"></div>
        <label>Note*</label>
        <textarea
          class="input_field"
          required
          formControlName="add_card_note"
          cols="30"
          rows="2"
        ></textarea>
      </div>
      <div class="modal-footer text-right">
        <div>
          <button [disabled]="!addCardNote" class="btn btn-primary">Add</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!--Verify Stripe Bank Modal-->
<ng-container *ngIf="modalType == _customer.modalTypes.verifyBankForm">
  <div class="modal-dialog modal-md modal-dialog-centered customModal">
    <div class="modal-content border-0">
      <form [formGroup]="verifyBankForm" (ngSubmit)="verifyBankData()">
        <div class="modal-header d-flex justify-content-between align-items-center bg-primary">
          <h4 class="modal-title fs-18 text-white">Verify Bank Account Details</h4>
           <img src="assets/images/icons/white-cross.svg" alt="" class="cursor hw-24" (click)="cancel()">
        </div>
        <div class="modal-body">
          <div
            class="row m-0"
            style="background: #f1f1f1; border-radius: 4px; padding: 10px"
          >
            <div class="col-12">
              <div class="row mb-2">
                <div class="col-xs-12 col-sm-5">Bank Name:</div>
                <div class="col-xs-12 col-sm-7">
                  <span class="h4 text-bold">
                    {{ bank_data.brand }}
                  </span>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-xs-12 col-sm-5">Routing Number:</div>
                <div class="col-xs-12 col-sm-7">
                  <span class="h4 text-bold">
                    {{ bank_data.funding }}
                  </span>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-xs-12 col-sm-5">Account Number:</div>
                <div class="col-xs-12 col-sm-7">
                  <span class="h4 text-bold">
                    ********{{ bank_data.last4 }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-md-12 m-t-10">
              <label class="text-bold">Kindly Enter the Micro Deposits:</label>
            </div>
            <div class="col-6 input-container">
              <label class="text-bold">First Amount*</label>
              <input
                type="text"
                required
                formControlName="first_amount"
                class="form-control input_txt"
                [min]="0"
                [max]="99"
                maxlength="2"
                numbersOnly
                placeholder="12"
              />
              <span class="zero_txt">$0.</span>
              <span class="usd">USD</span>
            </div>
            <div class="col-6 input-container">
              <label class="text-bold">Second Amount*</label>
              <input
                type="text"
                required
                formControlName="second_amount"
                class="form-control input_txt"
                [min]="0"
                [max]="99"
                maxlength="2"
                numbersOnly
                placeholder="12"
              />
              <span class="zero_txt">$0.</span>
              <span class="usd">USD</span>
            </div>
          </div>
          <div class="form-group pt-2">
            <label>Notes*</label>
            <textarea
              required
              formControlName="notes"
              class="input_field"
              trim="true"
            ></textarea>
            <p
              class="text-danger"
              *ngIf="
                verifyBankForm.controls['notes'].touched ||
                verifyBankForm.controls['notes'].dirty
              "
            >
              Please enter note.
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="cancel()">
            Cancel
          </button>
          <button class="btn btn-primary" type="submit">Verify</button>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<!--ACH-->
<ng-container *ngIf="modalType == _customer.modalTypes.appAchForm">
  <div class="modal-dialog modal-md">
    <div class="modal-content border-0">
      <form
        [formGroup]="appAchForm"
        (ngSubmit)="appAchForm.valid && enableDisableAch()"
      >
        <div class="modal-header d-flex justify-content-between align-items-center bg-primary">
          <h4 class="modal-title fs-18 text-white">
            {{ patient.show_ach == 1 ? "Disable" : "Enable" }} ACH
          </h4>
           <img src="assets/images/icons/white-cross.svg" alt="" class="cursor hw-24" (click)="cancel()">
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label
              >Are you sure you want to
              {{ patient.show_ach == 1 ? "Disable" : "Enable" }} ACH for this
              Customer?</label
            >
            <div class="clearfix"></div>
            <label>Reason/Note:</label>
            <textarea
              class="input_field"
              required
              formControlName="achnote"
              cols="30"
              rows="4"
            ></textarea>
            <p
              class="text-danger"
              *ngIf="
                appAchForm.controls['achnote'].touched ||
                appAchForm.controls['achnote'].dirty
              "
            >
              Please enter note.
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <div class="d-flex">
            <div class="ml-auto">
              <button
                type="button"
                class="btn btn-outline-secondary mr-4"
                (click)="cancel()"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">
                {{ extra_data.show_ach == 1 ? "Disable" : "Enable" }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<!--Add Stripe Bank Modal-->
<ng-container *ngIf="modalType == _customer.modalTypes.updateBankForm">
  <div class="modal-dialog modal-md">
    <div class="modal-content border-0">
      <form [formGroup]="updateBankForm" (ngSubmit)="addStripeBank()">
        <div class="modal-header d-flex justify-content-between align-items-center bg-primary">
          <h4 class="modal-title fs-18 text-white">Add Bank Account - Stripe Micro Deposit</h4>
           <img src="assets/images/icons/white-cross.svg" alt="" class="cursor hw-24" (click)="cancel()">
        </div>
        <div class="modal-body">
          <div class="row pb-4">
            <div class="col-md-6">
              <label for="bType">Account Type*</label>
              <select
                formControlName="account_type"
                id="bType"
                class="input_field"
                required
              >
                <option value="0" selected>Choose Account Type</option>
                <option value="1">Individual</option>
                <option value="2">Company</option>
              </select>
            </div>
            <div class="col-md-6">
              <label>Account Holder Name*</label>
              <input
                required
                type="text"
                class="input_field"
                formControlName="accountName"
                [ngClass]="{
                  'input-error':
                    updateBankForm.controls['accountName'].invalid &&
                    (updateBankForm.controls['accountName'].touched ||
                      updateBankForm.controls['accountName'].dirty)
                }"
                autocomplete="none"
                trim="true"
              />
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-md-6">
              <label>Routing Number*</label>
              <input
                required
                type="text"
                numbersOnly
                maxlength="9"
                class="input_field"
                formControlName="accountRouting"
                [ngClass]="{
                  'input-error':
                    updateBankForm.controls['accountRouting'].invalid &&
                    (updateBankForm.controls['accountRouting'].touched ||
                      updateBankForm.controls['accountRouting'].dirty)
                }"
                autocomplete="none"
                trim="true"
              />
            </div>
            <div class="col-md-6">
              <label>Confirm Routing Number* </label>
              <input
                required
                type="text"
                maxlength="9"
                class="input_field"
                name="accountRoutingConfirm"
                [ngClass]="{
                  'input-error':
                    updateBankForm.controls['accountRoutingConfirm'].invalid &&
                    (updateBankForm.controls['accountRoutingConfirm'].touched ||
                      updateBankForm.controls['accountRoutingConfirm'].dirty)
                }"
                autocomplete="none"
                trim="true"
                numbersOnly
              />
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-md-6">
              <label>Account Number*</label>
              <input
                required
                type="text"
                numbersOnly
                class="input_field"
                name="accountNumber"
                [ngClass]="{
                  'input-error':
                    updateBankForm.controls['accountNumber'].invalid &&
                    (updateBankForm.controls['accountNumber'].touched ||
                      updateBankForm.controls['accountNumber'].dirty)
                }"
                autocomplete="none"
                maxlength="15"
                trim="true"
              />
              <!-- <p class="text-danger m-0" *ngIf="accountNumber.errors?.pattern">Please enter a valid account number</p> -->
            </div>
            <div class="col-md-6">
              <label>Confirm Account Number* </label>
              <input
                required
                type="text"
                numbersOnly
                maxlength="9"
                class="input_field"
                formControlName="accountNumberConfirm"
                [ngClass]="{
                  'input-error':
                    updateBankForm.controls['accountNumberConfirm'].invalid &&
                    (updateBankForm.controls['accountNumberConfirm'].touched ||
                      updateBankForm.controls['accountNumberConfirm'].dirty)
                }"
                autocomplete="none"
                maxlength="15"
                trim
              />
            </div>
          </div>
          <div class="form-group">
            <label>Notes*</label>
            <textarea
              required
              formControlName="notes"
              class="input_field"
              trim="true"
            ></textarea>
            <p
              class="text-danger"
              *ngIf="
                (updateBankForm.controls['notes'].touched ||
                  updateBankForm.controls['notes'].dirty) &&
                updateBankForm.controls['notes'].invalid
              "
            >
              Please enter note.
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <div class="flex-container">
            <div class="flex-item text-left">
              <button
                type="button"
                class="btn btn-outline-secondary mr-2"
                (click)="cancel()"
              >
                Cancel
              </button>
            </div>
            <div class="flex-item text-right">
              <button
                type="submit"
                class="btn btn-primary"
                [disabled]="updateBankForm.invalid"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<!-- Send link for add bank-->
<ng-container *ngIf="modalType == _customer.modalTypes.addBankLinkForm">
  <div class="modal-dialog modal-md">
    <div class="modal-content border-0">
      <form [formGroup]="addBankLinkForm" (ngSubmit)="addBankLinkFn()">
        <div class="modal-header d-flex justify-content-between align-items-center bg-primary">
          <h4 class="modal-title fs-18 text-white">Send Add Bank Link</h4>
           <img src="assets/images/icons/white-cross.svg" alt="" class="cursor hw-24" (click)="cancel()">
        </div>
        <div class="modal-body">
          <div class="col-12 pl-0">
            <h4>
              Are you sure, you want to send the add bank account link to the
              following contact information?
            </h4>
            <div class="form-group">
              <label><b>Email Address*</b></label>
              <input
                type="email"
                required
                email
                class="input_field"
                [ngClass]="{
                  'has-error':
                    addBankLinkForm.controls['email'].invalid &&
                    addBankLinkForm.controls['email'].touched
                }"
                placeholder="Email Address"
                formControlName="email"
              />
              <p
                *ngIf="
                  addBankLinkForm.controls['email'].invalid &&
                  addBankLinkForm.controls['email'].touched
                "
                class="text-danger"
              >
                Please enter a valid email
              </p>
            </div>
            <div class="form-group">
              <label><b>Mobile Number*</b></label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroupPrepend3">{{
                    extra_data.patient_mobile_country_code
                  }}</span>
                </div>
                <input
                  type="text"
                  required
                  
                  class="input_field"
                  formControlName="phone"
                  type="text"
                  [mask]="_shared.mask"
                />
              </div>
              <p
                *ngIf="
                  addBankLinkForm.controls['phone'].invalid &&
                  addBankLinkForm.controls['phone'].touched
                "
                class="text-danger"
              >
                Please enter a valid mobile number
              </p>
            </div>
            <div class="form-group">
              <label><b>Notes*</b></label>
              <textarea
                required
                formControlName="notes"
                class="input_field"
                trim="true"
              ></textarea>
              <p
                class="text-danger"
                *ngIf="
                  (addBankLinkForm.controls['notes'].touched ||
                    addBankLinkForm.controls['notes'].dirty) &&
                  addBankLinkForm.controls['notes'].invalid
                "
              >
                Please enter note.
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary mr-2"
            (click)="cancel()"
          >
            Cancel
          </button>
          <button class="btn btn-primary" type="submit">Send</button>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<!-- Send link for verify bank-->
<ng-container *ngIf="modalType == _customer.modalTypes.verifyBankLinkForm">
  <div class="modal-dialog modal-md">
    <div class="modal-content border-0">
      <form [formGroup]="verifyBankLinkForm" (ngSubmit)="verifyBankLinkFn()">
        <div class="modal-header d-flex justify-content-between align-items-center bg-primary">
          <h4 class="modal-title fs-18 text-white">Send Micro Deposit Link</h4>
           <img src="assets/images/icons/white-cross.svg" alt="" class="cursor hw-24" (click)="cancel()">
        </div>
        <div class="modal-body">
          <div class="col-12 pl-0">
            <h4>
              Are you sure, you want to send the micro deposit link to the
              following contact information?
            </h4>
            <div class="form-group">
              <label><b>Email Address*</b></label>
              <input
                type="email"
                email
                class="input_field"
                [ngClass]="{
                  'has-error':
                    verifyBankLinkForm.controls['email'].invalid &&
                    verifyBankLinkForm.controls['email'].touched
                }"
                placeholder="Email Address"
                formControlName="email"
                required
              />
              <p
                *ngIf="
                  verifyBankLinkForm.controls['email'].invalid &&
                  verifyBankLinkForm.controls['email'].touched
                "
                class="text-danger"
              >
                Please enter a valid email
              </p>
            </div>
            <div class="form-group">
              <label><b>Mobile Number*</b></label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroupPrepend3">{{
                    patient.patient_mobile_country_code
                  }}</span>
                </div>
                <input
                  type="text"
                  required
                  maxlength="15"
                  minlength="12"
                  formControlName="input_field"
                  name="phone"
                  type="text"
                  [mask]="_shared.mask"
                />
              </div>
              <p
                *ngIf="
                  verifyBankLinkForm.controls['phone'].invalid &&
                  verifyBankLinkForm.controls['phone'].touched
                "
                class="text-danger"
              >
                Please enter a valid mobile number
              </p>
            </div>
            <div class="form-group">
              <label><b>Notes*</b></label>
              <textarea
                required
                formControlName="notes"
                class="input_field"
                trim="true"
              ></textarea>
              <p
                class="text-danger"
                *ngIf="
                  (verifyBankLinkForm.controls['notes'].touched ||
                    verifyBankLinkForm.controls['notes'].dirty) &&
                  verifyBankLinkForm.controls['notes'].invalid
                "
              >
                Please enter note.
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="cancel()">
            Cancel
          </button>
          <button class="btn btn-primary" type="submit">Send</button>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<!-- Make Default-->
<ng-container *ngIf="modalType == _customer.modalTypes.makeDefaultAddressForm">
  <div class="modal-dialog modal-md">
    <div class="modal-content border-0">
      <form
        [formGroup]="makeDefaultAddressForm"
        (ngSubmit)="makeDefaultAddress()"
      >
        <div class="modal-header d-flex justify-content-between align-items-center bg-primary">
          <h4 class="modal-title fs-18 text-white">Make Default Address</h4>
          <img src="assets/images/icons/white-cross.svg" alt="" class="cursor hw-24" (click)=" cancel();
          makeDefaultAddressForm.reset();
          addresses.selected_contracts = [];
          addresses.update_address.choose_option = 0">
        </div>
        <div class="modal-body">
          <label
            >Do you want to update this address for other financed Contracts
            ?</label
          >
          <div class="form-check form-check-inline">
            <input
              tappable
              class="form-check-input"
              type="radio"
              formControlName="all"
              value="1"
              id="all"
            />
            <label tappable class="form-check-label" for="all">
              For all financed Contracts.
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              tappable
              class="form-check-input"
              type="radio"
              formControlName="selected"
              value="2"
              id="selected"
            />
            <label tappable class="form-check-label" for="selected">
              For selected Contracts
            </label>
          </div>
          <div class="form-check form-check-inline my-2 mb-4">
            <input
              tappable
              class="form-check-input"
              type="radio"
              formControlName="no"
              value="3"
              id="no"
            />
            <label tappable class="form-check-label" for="no">
              For Profile only
            </label>
          </div>
          <div *ngIf="addresses.update_address.choose_option == 2">
            <label
              >Choose contract for which you want to update this address as
              default.</label
            >
            <div
              style="max-height: 250px; overflow-y: auto"
              class="table-responsive"
            >
              <table class="table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Contract Code</th>
                    <th>Contract Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of addresses.contracts; let i = index">
                    <td class="text-center">
                      <input
                        style="position: relative"
                        type="checkbox"
                        class="form-check-input"
                        (change)="selectedContracts(data.contract_id)"
                      />
                      {{ i + 1 }}
                    </td>
                    <td>{{ data.contract_code ? data.contract_code : "-" }}</td>
                    <td>
                      <span
                        class="badge"
                        [ngClass]="{
                          'badge-primary':
                            data.contract_status == 1 ||
                            data.contract_status == 2,
                          'badge-warning': data.contract_status == 3,
                          'badge-success': data.contract_status == 4,
                          'badge-danger': data.contract_status == 5
                        }"
                      >
                        {{
                          data.contract_status
                            ? data.contract_status == 1 ||
                              data.contract_status == 2
                              ? "Active"
                              : data.contract_status == 3
                              ? "Overdue"
                              : data.contract_status == 4
                              ? "Completed"
                              : "Canceled"
                            : "-"
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <label class="m-0 w-100">Notes*</label>
          <textarea
            [ngClass]="{
              'border border-danger':
                makeDefaultAddressForm.controls['updateAddressNote'].invalid &&
                makeDefaultAddressForm.controls['updateAddressNote'].touched
            }"
            class="input_field"
            required
            formControlName="updateAddressNote"
            cols="30"
            rows="3"
          >
          </textarea>
          <p
            class="text-danger"
            *ngIf="
              (makeDefaultAddressForm.controls['updateAddressNote'].touched ||
                makeDefaultAddressForm.controls['updateAddressNote'].dirty) &&
              makeDefaultAddressForm.controls['updateAddressNote'].invalid
            "
          >
            Please enter a valid note.
          </p>
          <div class="modal-footer">
            <button
              type="button"
              (click)="
                cancel();
                addresses.selected_contracts = [];
                addresses.update_address.choose_option = 0
              "
              class="btn btn-default"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="
                !addresses.update_address.choose_option ||
                addresses.update_address.choose_option == 0 ||
                (addresses.update_address.choose_option == 2 &&
                  addresses.selected_contracts == [])
              "
            >
              Make Default
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<!-- Add Alternate Email-->
<ng-container *ngIf="modalType == _customer.modalTypes.addAlternateEmailForm">
  <div class="modal-dialog modal-md">
    <div class="modal-content border-0">
      <form
        [formGroup]="addAlternateEmailForm"
        (ngSubmit)="addAlternateEmail()"
      >
        <div class="modal-header d-flex justify-content-between align-items-center bg-primary">
          <h4 class="modal-title fs-18 text-white">Add Alternate Email</h4>
           <img src="assets/images/icons/white-cross.svg" alt="" class="cursor hw-24" (click)="cancel()">
        </div>
        <div class="modal-body">
          <label class="m-0 w-100">Alternate Email</label>
          <input
            type="email"
            email
            class="input_field"
            [ngClass]="{
              'has-error':
                addAlternateEmailForm.controls['alternateEmail'].invalid &&
                addAlternateEmailForm.controls['alternateEmail'].touched
            }"
            formControlName="alternateEmail"
          />
          <p
            *ngIf="
              (addAlternateEmailForm.controls['alternateEmail'].touched ||
                addAlternateEmailForm.controls['alternateEmail'].dirty) &&
              addAlternateEmailForm.controls['alternateEmail'].invalid
            "
            class="text-danger"
          >
            Please enter a valid email
          </p>
          <label class="m-0 mt-3 w-100">Notes*</label>
          <textarea
            [ngClass]="{
              'border border-danger':
                addAlternateEmailForm.controls['addEditAlternateEmaitNote']
                  .invalid &&
                addAlternateEmailForm.controls['addEditAlternateEmaitNote']
                  .touched
            }"
            class="input_field"
            required
            formControlName="addEditAlternateEmaitNote"
            cols="30"
            rows="3"
          >
          </textarea>
          <p
            class="text-danger"
            *ngIf="
              (addAlternateEmailForm.controls['addEditAlternateEmaitNote']
                .touched ||
                addAlternateEmailForm.controls['addEditAlternateEmaitNote']
                  .dirty) &&
              addAlternateEmailForm.controls['addEditAlternateEmaitNote']
                .invalid
            "
          >
            Please enter a valid note.
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="
              addAlternateEmailForm.controls['addEditAlternateEmaitNote']
                .invalid
            "
          >
            Add
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<!-- Add Alternate phone-->
<ng-container *ngIf="modalType == _customer.modalTypes.addAlternatePhoneForm">
  <div class="modal-dialog modal-md">
    <div class="modal-content border-0">
      <form
        [formGroup]="addAlternatePhoneForm"
        (ngSubmit)="addAlternateMobile()"
      >
        <div class="modal-header d-flex justify-content-between align-items-center bg-primary">
          <h4 class="modal-title fs-18 text-white">Add Alternate Phone Number</h4>
           <img src="assets/images/icons/white-cross.svg" alt="" class="cursor hw-24" (click)="cancel()">
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label><b>Mobile Number*</b></label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroupPrepend3">{{
                  extra_data.patient_mobile_country_code
                }}</span>
              </div>
              <input
                maxlength="15"
                minlength="10"
                [ngClass]="{
                  'has-error':
                    addAlternatePhoneForm.controls['alternatePhone'].invalid &&
                    addAlternatePhoneForm.controls['alternatePhone'].touched
                }"
                formControlName="alternatePhone"
                class="input_field"
                type="tel"
                pattern="[0-9]+"
                [mask]="_shared.mask"
              />
            </div>
            <p
              *ngIf="
                (addAlternatePhoneForm.controls['alternatePhone'].touched ||
                  addAlternatePhoneForm.controls['alternatePhone'].dirty) &&
                addAlternatePhoneForm.controls['alternatePhone'].invalid
              "
              class="text-danger"
            >
              Please enter a valid phone number
            </p>
          </div>
          
          <label class="m-0 mt-3 w-100">Notes*</label>
          <textarea
            [ngClass]="{
              'border border-danger':
                addAlternatePhoneForm.controls['addEditAlternatePhoneNote']
                  .invalid &&
                addAlternatePhoneForm.controls['addEditAlternatePhoneNote']
                  .touched
            }"
            [ngClass]="{
              'has-error':
                addAlternatePhoneForm.controls['alternatePhone'].invalid &&
                addAlternatePhoneForm.controls['alternatePhone'].touched
            }"
            class="input_field"
            required
            formControlName="addEditAlternatePhoneNote"
            cols="30"
            rows="3"
          >
          </textarea>
          <p
            class="text-danger"
            *ngIf="
              (addAlternatePhoneForm.controls['addEditAlternatePhoneNote']
                .touched ||
                addAlternatePhoneForm.controls['addEditAlternatePhoneNote']
                  .dirty) &&
              addAlternatePhoneForm.controls['addEditAlternatePhoneNote']
                .invalid
            "
          >
            Please enter a valid note.
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="
              addAlternatePhoneForm.controls['addEditAlternatePhoneNote']
                .invalid
            "
          >
            Add
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<!-- Add New Address Modal-->
<ng-container *ngIf="modalType == _customer.modalTypes.addNewAddressForm">
  <div class="modal-dialog modal-md modal-dialog-centered customModal">
    <div class="modal-content border-0">
      <form [formGroup]="addNewAddressForm" (ngSubmit)="addNewAddress()">
        <div class="modal-header d-flex justify-content-between align-items-center bg-primary">
          <h4 class="modal-title fs-18 text-white">Add Customer Address</h4>
           <img src="assets/images/icons/white-cross.svg" alt="" class="cursor hw-24" (click)="cancel()">
        </div>
        <div class="modal-body">
          <div class="row m-0">
            <div class="col-sm-6">
              <label class="m-0">Address*</label>
              <app-google-zip
                class="w-100"
                
                (setAddress)="getZipselected($event, 'zip')"
                id="zipPostalCode"
                [country_code]="patient.country_code"
                
              >
              </app-google-zip>
            </div>
            <div class="col-sm-6">
              <label class="m-0">Zipcode*</label>
              <div class="form-group">
                <input
                  readonly
                  type="text"
                  formControlName="customer_zip"
                  class="input_field"
                />
              </div>
            </div>
            <div class="col-sm-6 mt-2">
              <label class="m-0">City</label>
              <input
                type="text"
                formControlName="customer_city"
                class="input_field"
                readonly
              />
            </div>
            <div class="col-sm-6 mt-2">
              <label class="m-0">State</label>
              <input
                type="text"
                formControlName="customer_state"
                class="input_field"
                readonly
              />
            </div>
            <div class="col-sm-12 mt-2">
              <label class="m-0">Notes*</label>
              <textarea
                [ngClass]="{
                  'border border-danger':
                    addNewAddressForm.controls['addAddressNote'].invalid &&
                    addNewAddressForm.controls['addAddressNote'].touched
                }"
                class="input_field"
                required
                formControlName="addAddressNote"
                cols="30"
                rows="3"
              >
              </textarea>
              <p
                class="text-danger"
                *ngIf="
                  (addNewAddressForm.controls['addAddressNote'].touched ||
                    addNewAddressForm.controls['addAddressNote'].dirty) &&
                  addNewAddressForm.controls['addAddressNote'].invalid
                "
              >
                Please enter a valid note.
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="addNewAddressForm.invalid"
          >
            Add
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<!-- Send link for reauth bank-->
<ng-container *ngIf="modalType == _customer.modalTypes.bankLinkForm">
  <div class="modal-dialog modal-md">
    <div class="modal-content border-0">
      <form [formGroup]="bankLinkForm" (ngSubmit)="reAuthBankLinkFn()">
        <div class="modal-header d-flex justify-content-between align-items-center bg-primary">
          <h4 class="modal-title fs-18 text-white">Send Reauthentication Link</h4>
           <img src="assets/images/icons/white-cross.svg" alt="" class="cursor hw-24" (click)="cancel()">
        </div>
        <div class="modal-body">
          <div class="col-12 pl-0">
            <h4>
              Are you sure you want to send the reauthentication link to the
              following contact information?
            </h4>
            <div class="form-group">
              <label><b>Email Address*</b></label>
              <input
                type="email"
                email
                class="input_field"
                [ngClass]="{
                  'has-error':
                    bankLinkForm.controls['email'].invalid &&
                    bankLinkForm.controls['email'].touched
                }"
                placeholder="Email Address"
                formControlName="email"
                required
              />
              <p
                *ngIf="
                  bankLinkForm.controls['email'].invalid &&
                  bankLinkForm.controls['email'].touched
                "
                class="text-danger"
              >
                Please enter a valid email
              </p>
            </div>
            <div class="form-group">
              <label><b>Mobile Number*</b></label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroupPrepend3">{{
                    patient.patient_mobile_country_code
                  }}</span>
                </div>
                <input
                  type="text"
                  required
                  maxlength="15"
                  minlength="12"
                  class="input_field"
                  formControlName="phone"
                  type="text"
                  [mask]="_shared.mask"
                />
              </div>
              <p
                *ngIf="
                  bankLinkForm.controls['phone'].invalid &&
                  bankLinkForm.controls['phone'].touched
                "
                class="text-danger"
              >
                Please enter a valid mobile number
              </p>
            </div>
            <div class="form-group">
              <label><b>Notes*</b></label>
              <textarea
                required
                formControlName="notes"
                class="input_field"
                trim="true"
              ></textarea>
              <p
                class="text-danger"
                *ngIf="
                  (bankLinkForm.controls['notes'].touched ||
                    bankLinkForm.controls['notes'].dirty) &&
                  bankLinkForm.controls['notes'].invalid
                "
              >
                Please enter note.
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="cancel()">
            Cancel
          </button>
          <button class="btn btn-primary" type="submit">Send</button>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<!--Contract Activation/Deactivation-->
<ng-container *ngIf="modalType == _customer.modalTypes.appContractForm">
  <div class="modal-dialog modal-md">
    <div class="modal-content border-0">
      <form
        [formGroup]="appContractForm"
        (ngSubmit)="enableDisableContractCreation()"
      >
        <div class="modal-header d-flex justify-content-between align-items-center bg-primary">
          <h4 class="modal-title fs-18 text-white">
            {{ patient.can_activate_contract == 1 ? "Disallow" : "Allow" }}
            Contract Revival
          </h4>
           <img src="assets/images/icons/white-cross.svg" alt="" class="cursor hw-24" (click)="cancel()">
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label
              >Are you sure you want to
              {{ patient.can_activate_contract == 1 ? "Disallow" : "Allow" }}
              Contract Creation for this Customer?</label
            >
            <div class="clearfix"></div>
            <label>Reason/Note:</label>
            <textarea
              class="input_field"
              required
              formControlName="achnote"
              cols="30"
              rows="4"
            ></textarea>
            <p
              class="text-danger"
              *ngIf="
                (appContractForm.controls['achnote'].touched ||
                  appContractForm.controls['achnote'].dirty) &&
                appContractForm.controls['achnote'].invalid
              "
            >
              Please enter note.
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <div class="d-flex">
            <div class="ml-auto">
              <button
                type="button"
                class="btn btn-outline-secondary mr-4"
                (click)="cancel()"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Confirm</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<!-- Change customer payment settings-->
<ng-container
  *ngIf="modalType == _customer.modalTypes.changePaymentSettingsForm"
>
  <div class="modal-dialog modal-md">
    <div class="modal-content border-0">
      <form
        [formGroup]="changePaymentSettingsForm"
        (ngSubmit)="changeCustomerPaymentSettings()"
      >
        <div class="modal-header d-flex justify-content-between align-items-center bg-primary">
          <h4 class="modal-title fs-18 text-white">Change Allowed Payment Methods</h4>
           <img src="assets/images/icons/white-cross.svg" alt="" class="cursor hw-24" (click)="cancel()">
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-10">
              <label>Can user pay via Google Pay?</label>
            </div>
            <div class="col-sm-2 p-0">
              <div class="form-group mt-1">
                <label class="switch switch-sm switch-success ml-1 mr-1">
                  <input type="checkbox" formControlName="show_google_pay" />
                  <span></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-10">
              <label>Can user pay via Apple Pay?</label>
            </div>
            <div class="col-sm-2 p-0">
              <div class="form-group mt-1">
                <label class="switch switch-sm switch-success ml-1 mr-1">
                  <input type="checkbox" formControlName="show_apple_pay" />
                  <span></span>
                </label>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Note:</label>
            <textarea
              class="input_field"
              required
              formControlName="paymentNote"
              cols="30"
              rows="4"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <div class="d-flex">
            <div class="ml-auto">
              <button
                type="button"
                class="btn btn-outline-secondary mr-4"
                (click)="cancel()"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary" [disabled]="changePaymentSettingsForm.invalid" [ngClass]="{'disable': changePaymentSettingsForm.invalid}">Confirm</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<!-- Re Auth email setting-->
<!-- <ng-container *ngIf="modalType == _customer.modalTypes.reAuthEmailForm">
  <div class="modal-dialog modal-md">
    <div class="modal-content border-0">
      <form
        [formGroup]="reAuthEmailForm"
        (ngSubmit)="
          reAuthEmailForm.valid &&
            reAuthEmailSettings()
        "
      >
        <div class="modal-header d-flex justify-content-between align-items-center bg-primary">
          <h4 class="modal-title fs-18 text-white">Re-Auth Email</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="
              resetReAuthEmailForm(reAuthEmailForm, reAuthEmail);
              reAuthEmail.hide()
            "
          >
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-10">
              <label>Re-Auth Email</label>
            </div>
            <div class="col-sm-2 p-0">
              <div class="form-group mt-1">
                <label class="switch switch-sm switch-success ml-1 mr-1">
                  <input type="checkbox" formControlName="send_reauth_email" />
                  <span></span>
                </label>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Note:</label>
            <textarea
              class="input_field"
              formControlName="reAuth_note"
              cols="30"
              rows="4"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <div class="d-flex">
            <div class="ml-auto">
              <button
                type="button"
                class="btn btn-outline-secondary mr-4"
                (click)="resetReAuthEmailForm(reAuthEmailForm, reAuthEmail)"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Confirm</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container> -->

<!-- Verify Full SSN-->
<ng-container *ngIf="modalType == _customer.modalTypes.fullSSNForm">
  <div class="modal-dialog modal-md">
    <div class="modal-content border-0">
      <form [formGroup]="fullSSNForm" (ngSubmit)="verifyFullSSN()">
        <div class="modal-header d-flex justify-content-between align-items-center bg-primary">
          <h4 class="modal-title fs-18 text-white">View Complete SSN</h4>
           <img src="assets/images/icons/white-cross.svg" alt="" class="cursor hw-24" (click)="cancel()">
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="">Password</label>
            <div class="input-group">
              <input
                class="input_field"
                [type]="showPwd ? 'text' : 'password'"
                formControlName="password"
                placeholder="Password"
                required
              />
              <div
                class="input-group-append"
                tappable
                (click)="showPwd = !showPwd"
              >
                <div class="input-group-text">
                  <i class="fa fa-eye" *ngIf="showPwd"></i>
                  <i class="fa fa-eye-slash" *ngIf="!showPwd"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Note:</label>
            <textarea
              class="input_field"
              formControlName="note"
              cols="30"
              rows="4"
              trim="true"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <div class="d-flex">
            <div class="ml-auto">
              <button
                type="button"
                class="btn btn-outline-secondary mr-4"
                (click)="cancel()"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Confirm</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="modalType == _customer.modalTypes.approval_code">
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content border-0">
      <form
        *ngIf="extra_data.leads_data"
        [formGroup]="approval_code"
        onsubmit="resend_link()"
      >
        <div class="modal-header d-flex justify-content-between align-items-center bg-primary">
          <h4 class="modal-title fs-18 text-white">Resend Pre-Approval Application</h4>
           <img src="assets/images/icons/white-cross.svg" alt="" class="cursor hw-24" (click)="cancel()">
        </div>
        <div class="modal-body m-3">
          <div class="code--approval-wrap--customer-email">
            <input
              type="text"
              placeholder="Customer Email"
              formControlName="email"
            />
          </div>

          <!-- <div class="code--approval-wrap--customer-email" style="position: relative;">
              <span *ngIf="approval_code.get('mobile').value" class="pt-2 pr-1 font-weight-bold dollar_input"
                style="position: absolute;left: 14px;bottom: 17px;">+1-
              </span>
              <input type="text" placeholder="Customer Mobile" formControlName="mobile"
                [textMask]="{mask: mainService.getPhoneMask()}"
                [ngStyle]="{'padding-left': approval_code.get('mobile').value ? '40px' : '1rem'}">
            </div> -->

          <div class="row p-0 m-0">
            <div class="col-md-2 m-0 p-0">
              <select
                class="form-select select_class"
                aria-label="Default select example"
                disabled
                style="padding: 13px 10px 16px 12px"
              >
                <option value="1">
                  {{
                    extra_data.country_info &&
                      extra_data.country_info.mobile_code
                  }}
                </option>
              </select>
            </div>
            <div class="col-md-10 m-0 p-0">
              <input
                class="form-control input_field pt-3"
                formControlName="mobile"
                placeholder="Customer Mobile"
                [ngStyle]="{
                  'padding-left': approval_code.get('mobile')?.value
                    ? '10px'
                    : '1rem'
                }"
                [mask]="phone_mask"
              />
            </div>
          </div>

          <h5 class="sub-heading mt-3">
            Send website url or pre-approval link to customer for fill missing
            details
          </h5>
          <div class="mb-4">
            <div
              class="code--approval-wrap--input-radio"
              (click)="extra_data.link_type = 1"
            >
              <label class="switch switch-sm switch-success ml-1 mr-1">
                <input type="checkbox" [checked]="extra_data.link_type == 1" />
                <span></span>
              </label>
              <input
                type="text"
                placeholder="Website URL"
                formControlName="website_url"
                (focus)="focusIn($event)"
                (focusout)="focusOut($event)"
              />
            </div>
            <p
              class="mat-error"
              *ngIf="
                approval_code.get('website_url')?.valid == false &&
                approval_code.get('website_url')?.dirty == true &&
                !extra_data.is_website_input_focus
              "
            >
              Please enter a valid link
            </p>
          </div>
          <div
            class="code--approval-wrap--input-radio mb-4"
            (click)="extra_data.link_type = 2"
          >
            <label class="switch switch-sm switch-success ml-1 mr-1">
              <input type="checkbox" [checked]="extra_data.link_type == 2" />
              <span></span>
            </label>
            <input
              type="text"
              placeholder="Pre-approval url"
              formControlName="request_panel_link"
              readonly
            />
          </div>
          <div class="follow-up-wrapper--description">
            <textarea
              name="comment"
              formControlName="notes"
              placeholder="Note (optional)"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col">
              <button class="btn btn-default" (click)="cancel()">Cancel</button>
            </div>
            <div class="col">
              <button class="btn btn-primary">Send</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>
