import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SharedService } from '../shared.service';
import { MainService } from '../main.service';
import { localStorageService } from '../localstorage.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-export-csr-notes',
  templateUrl: './export-csr-notes.component.html',
  styleUrl: './export-csr-notes.component.scss'
})
export class ExportCsrNotesComponent implements OnInit {
  @Input() label = 'Export';
  @Input() notes: any;
  @Input() profile: any;
  @Input() type: any; // 1 for Business, 2 for patient, 3 for partner, 4 for contracts, //5 for User //6 for admin //7 for subscription
  timezone: any = this._shared.getTimezone();
  timezoneName: any = this._shared.getTimezoneName();
  constructor(
    public _shared: SharedService,
    public _main: MainService,
    public datePipe: DatePipe,
    private _local: localStorageService,
    private _router: ActivatedRoute
  ) { }

  ngOnInit() {
    this._router.queryParams.subscribe((value: any) => {
      this.timezone = value.timezone;
      this.timezoneName = this._shared.getTimezoneName();
    });
  }

  exportData(){
    
  }

  exportReport(ext: any) {
    let list: any = [];
    this.type = parseInt(this.type);
    switch(this.type){
      case 1:
        list [0] = {
          date_added: 'Date Created',
          followup_date: 'Followup Date',
          contact_type: 'Type of Contact',
          contact_result: 'Result of Contact',
          assigned_by: 'Assigned By',
          assigned_by_email: 'Assigned By Email',
          assigned_to: 'Assigned To',
          assigned_to_email: 'Assigned To Email',
          notes: 'Note',
          action_value: 'Action'
        }
        let doctor_notes = this.notes;
        console.log(doctor_notes)
        for (var i = 0; i < doctor_notes.length; i++) {
          list[i + 1] = {};
          list[i + 1].date_added = doctor_notes[i].date_added?this.datePipe.transform(doctor_notes[i].date_added, 'medium',this.timezone):'-';
          list[i + 1].followup_date = doctor_notes[i].followup_date?this.datePipe.transform(doctor_notes[i].followup_date, 'MM-dd-yyyy','+0000'):'-';
          switch(doctor_notes[i].contact_type){
            case 1: list[i + 1].contact_type = 'Incoming'; break;
            case 2: list[i + 1].contact_type = 'Outgoing'; break;
            case 3: list[i + 1].contact_type = 'Email'; break;
            case 4: list[i + 1].contact_type = 'Text'; break;
            case 5: list[i + 1].contact_type = 'Chat'; break;
            case 6: list[i + 1].contact_type = 'Verification Call'; break;
            case 7: list[i + 1].contact_type = 'Potential Doctor'; break;
            case 8: list[i + 1].contact_type = 'Validation'; break;
          }
          switch(doctor_notes[i].contact_result){
            case 1: list[i + 1].contact_result = 'Voicemail'; break;
            case 2: list[i + 1].contact_result = 'No Answer'; break;
            case 3: list[i + 1].contact_result = 'Voicemail Full'; break;
            case 4: list[i + 1].contact_result = 'Answered'; break;
            default: list[i + 1].contact_result = '-'; break;
          }
          list[i + 1].assigned_by = doctor_notes[i].admin_name?doctor_notes[i].admin_name:'-';
          list[i + 1].assigned_by_email = doctor_notes[i].admin_email?doctor_notes[i].admin_email:'-';
          list[i + 1].assigned_to = doctor_notes[i].assigned_name?doctor_notes[i].assigned_name:'-';
          list[i + 1].assigned_to_email = doctor_notes[i].assigned_email?doctor_notes[i].assigned_email:'-';
          list[i + 1].notes = doctor_notes[i].note?doctor_notes[i].note:'-';
          list[i + 1].action_value =  doctor_notes[i].action_value?doctor_notes[i].action_value:'-';
            
        }
        if (ext == 0) {
          list.unshift({
            date_added: 'CSR Notes for '+this.profile.doctor_name,
          });
          this._shared.exportAsExcelFile(list, 'business_csr_notes', '.xlsx');
        } else if (ext == 1) {
          this._shared.exportAsExcelFile(list, 'business_csr_notes', '.csv');
        }
      break;

      case 2:
        list [0] = {
          date_added: 'Date Created',
          followup_date: 'Followup Date',
          contact_type: 'Type of Contact',
          contact_result: 'Result of Contact',
          assigned_by: 'Created By',
          assigned_by_email: 'Assigned By Email',
          assigned_to: 'Assigned To',
          assigned_to_email: 'Assigned To Email',
          notes: 'Note',
          action_value: 'Action'
        }
        let patient_notes = this.notes;
        console.log(patient_notes)
        for (var i = 0; i < patient_notes.length; i++) {
          list[i + 1] = {};
          console.log(patient_notes[i])
          list[i + 1].date_added = patient_notes[i].date_added || patient_notes[i].date_time ? this.datePipe.transform((patient_notes[i].date_added || patient_notes[i].date_time), 'medium',this.timezone):'-';
          list[i + 1].followup_date = patient_notes[i].followup_date?this.datePipe.transform(patient_notes[i].followup_date, 'MM-dd-yyyy','+0000'):'-';
          switch(patient_notes[i].contact_type){
            case 1: list[i + 1].contact_type = 'Incoming'; break;
            case 2: list[i + 1].contact_type = 'Outgoing'; break;
            case 3: list[i + 1].contact_type = 'Email'; break;
            case 4: list[i + 1].contact_type = 'Text'; break;
            case 5: list[i + 1].contact_type = 'Chat'; break;
            case 6: list[i + 1].contact_type = 'Verification Call'; break;
            case 7: list[i + 1].contact_type = 'Potential Business'; break;
            case 8: list[i + 1].contact_type = 'Validation'; break;
          }
          switch(patient_notes[i].contact_result){
            case 1: list[i + 1].contact_result = 'Voicemail'; break;
            case 2: list[i + 1].contact_result = 'No Answer'; break;
            case 3: list[i + 1].contact_result = 'Voicemail Full'; break;
            case 4: list[i + 1].contact_result = 'Answered'; break;
            default: list[i + 1].contact_result = '-'; break;
          }
          list[i + 1].assigned_by = patient_notes[i].admin_name?patient_notes[i].admin_name:'-';
          list[i + 1].assigned_by_email = patient_notes[i].admin_email?patient_notes[i].admin_email:'-';
          list[i + 1].assigned_to = patient_notes[i].assigned_name?patient_notes[i].assigned_name:'-';
          list[i + 1].assigned_to_email = patient_notes[i].assigned_email?patient_notes[i].assigned_email:'-';
          list[i + 1].notes = patient_notes[i].note || patient_notes[i].notes  ? patient_notes[i].note || patient_notes[i].notes :'-';
          list[i + 1].action_value =  patient_notes[i].action_value?patient_notes[i].action_value:'-';
            
        }
        if (ext == 0) {
          list.unshift({date_added: 'CSR Notes for '+ (this.profile.title =='None' ||  this.profile.title =='' ? '' : this.profile.title) + ' '+ this.profile.patient_first_name+' '+ this.profile.patient_last_name})
          this._shared.exportAsExcelFile(list, 'patient_csr_notes', '.xlsx');
        } else if (ext == 1) {
          this._shared.exportAsExcelFile(list, 'patient_csr_notes', '.csv');
        }
      break;

      case 3:
        list [0] = {
          date_added: 'Date Created',
          followup_date: 'Followup Date',
          contact_type: 'Type of Contact',
          contact_result: 'Result of Contact',
          assigned_by: 'Assigned By',
          assigned_by_email: 'Assigned By Email',
          assigned_to: 'Assigned To',
          assigned_to_email: 'Assigned To Email',
          notes: 'Note',
          action_value: 'Action'
          
        }
        let partner_notes = this.notes;
        console.log(partner_notes)
        for (var i = 0; i < partner_notes.length; i++) {
          list[i + 1] = {};
          console.log(partner_notes[i])
          list[i + 1].date_added = partner_notes[i].date_added?this.datePipe.transform(partner_notes[i].date_added, 'medium',this.timezone):'-';
          list[i + 1].followup_date = partner_notes[i].followup_date?this.datePipe.transform(partner_notes[i].followup_date, 'MM-dd-yyyy','+0000'):'-';
          switch(partner_notes[i].contact_type){
            case 1: list[i + 1].contact_type = 'Incoming'; break;
            case 2: list[i + 1].contact_type = 'Outgoing'; break;
            case 3: list[i + 1].contact_type = 'Email'; break;
            case 4: list[i + 1].contact_type = 'Text'; break;
            case 5: list[i + 1].contact_type = 'Chat'; break;
            case 6: list[i + 1].contact_type = 'Verification Call'; break;
            case 7: list[i + 1].contact_type = 'Potential Business'; break;
            case 8: list[i + 1].contact_type = 'Validation'; break;
          }
          switch(partner_notes[i].contact_result){
            case 1: list[i + 1].contact_result = 'Voicemail'; break;
            case 2: list[i + 1].contact_result = 'No Answer'; break;
            case 3: list[i + 1].contact_result = 'Voicemail Full'; break;
            case 4: list[i + 1].contact_result = 'Answered'; break;
            default: list[i + 1].contact_result = '-'; break;
          }
          list[i + 1].assigned_by = partner_notes[i].admin_name?partner_notes[i].admin_name:'-';
          list[i + 1].assigned_by_email = partner_notes[i].admin_email?partner_notes[i].admin_email:'-';
          list[i + 1].assigned_to = partner_notes[i].assigned_name?partner_notes[i].assigned_name:'-';
          list[i + 1].assigned_to_email = partner_notes[i].assigned_email?partner_notes[i].assigned_email:'-';
          list[i + 1].notes = partner_notes[i].note ? partner_notes[i].note :'-';
          list[i + 1].action_value =  partner_notes[i].action_value?partner_notes[i].action_value:'-';
            
        }
        if (ext == 0) {
          list.unshift({date_added: 'CSR Notes for '+ this.profile.title+ ' '+ this.profile.partner_name})
          this._shared.exportAsExcelFile(list, 'partner_csr_notes', '.xlsx');
        } else if (ext == 1) {
          this._shared.exportAsExcelFile(list, 'partner_csr_notes', '.csv');
        }
      break;

      case 4:
        list [0] = {
          date_added: 'Date Created',
          followup_date: 'Followup Date',
          contact_type: 'Type of Contact',
          contact_result: 'Result of Contact',
          assigned_by: 'Created By',
          assigned_by_email: 'Assigned By Email',
          assigned_to: 'Assigned To',
          assigned_to_email: 'Assigned To Email',
          notes: 'Note',
          action: 'Action',
          payment_mode: 'Payment Mode',
          amount: 'Amount',
          missing_count: 'Missing Count',
          next_payment_date : 'Next Payment Date'
        }
        let contract_notes = this.notes;
        console.log(contract_notes)
        for (var i = 0; i < contract_notes.length; i++) {
          list[i + 1] = {};
          // console.log(contract_notes[i])
          list[i + 1].date_added = contract_notes[i].date_added?this.datePipe.transform(contract_notes[i].date_added, 'medium',this.timezone):'-';
          list[i + 1].followup_date = contract_notes[i].followup_date?this.datePipe.transform(contract_notes[i].followup_date, 'MM-dd-yyyy','+0000'):'-';
          switch(contract_notes[i].contact_type){
            case 1: list[i + 1].contact_type = 'Incoming'; break;
            case 2: list[i + 1].contact_type = 'Outgoing'; break;
            case 3: list[i + 1].contact_type = 'Email'; break;
            case 4: list[i + 1].contact_type = 'Text'; break;
            case 5: list[i + 1].contact_type = 'Chat'; break;
            case 6: list[i + 1].contact_type = 'Verification Call'; break;
            case 7: list[i + 1].contact_type = 'Potential Business'; break;
            case 8: list[i + 1].contact_type = 'Validation'; break;
          }
          switch(contract_notes[i].contact_result){
            case 1: list[i + 1].contact_result = 'Voicemail'; break;
            case 2: list[i + 1].contact_result = 'No Answer'; break;
            case 3: list[i + 1].contact_result = 'Voicemail Full'; break;
            case 4: list[i + 1].contact_result = 'Answered'; break;
            default: list[i + 1].contact_result = '-'; break;
          }
          list[i + 1].assigned_by = contract_notes[i].admin_name?contract_notes[i].admin_name:contract_notes[i].name;
          list[i + 1].assigned_by_email = contract_notes[i].admin_email?contract_notes[i].admin_email:'-';
          list[i + 1].assigned_to = contract_notes[i].assigned_name?contract_notes[i].assigned_name:'-';
          list[i + 1].assigned_to_email = contract_notes[i].assigned_email?contract_notes[i].assigned_email:'-';
          list[i + 1].notes = contract_notes[i].note ? contract_notes[i].note :contract_notes[i].notes || contract_notes[i].arrangement_note;
          list[i + 1].action = contract_notes[i].action ? contract_notes[i].action : '-';
          list[i + 1].payment_mode = contract_notes[i].payment_mode ? contract_notes[i].payment_mode: '-';
          list[i + 1].amount = contract_notes[i].amount ? contract_notes[i].amount: '-';
          list[i + 1].missing_count = contract_notes[i].missing_count ? contract_notes[i].missing_count: '-';
          list[i + 1].next_payment_date = contract_notes[i].next_payment_date?this.datePipe.transform(contract_notes[i].next_payment_date, 'MM-dd-yyyy','+0000'):'-';
        }
        if (ext == 0) {
          list.unshift({date_added: 'CSR Notes for '+ this.profile.contract_code})
          this._shared.exportAsExcelFile(list, 'contract_notes', '.xlsx');
        } else if (ext == 1) {
          list.unshift({date_added: 'CSR Notes for '+ this.profile.contract_code})

          this._shared.exportAsExcelFile(list, 'contract_notes', '.csv');
        }
      break;

      case 5:
        case 2:
        list [0] = {
          date_added: 'Date Created',
          followup_date: 'Followup Date',
          contact_type: 'Type of Contact',
          contact_result: 'Result of Contact',
          assigned_by: 'Created By',
          assigned_by_email: 'Assigned By Email',
          assigned_to: 'Assigned To',
          assigned_to_email: 'Assigned To Email',
          notes: 'Note',
          action_value: 'Action'
        }
        let user_notes = this.notes;
        for (var i = 0; i < user_notes.length; i++) {
          list[i + 1] = {};
          console.log(user_notes[i])
          list[i + 1].date_added = user_notes[i].date_added || user_notes[i].date_time ? this.datePipe.transform((user_notes[i].date_added || user_notes[i].date_time), 'medium',this.timezone):'-';
          list[i + 1].followup_date = user_notes[i].followup_date?this.datePipe.transform(user_notes[i].followup_date, 'MM-dd-yyyy','+0000'):'-';
          switch(user_notes[i].contact_type){
            case 1: list[i + 1].contact_type = 'Incoming'; break;
            case 2: list[i + 1].contact_type = 'Outgoing'; break;
            case 3: list[i + 1].contact_type = 'Email'; break;
            case 4: list[i + 1].contact_type = 'Text'; break;
            case 5: list[i + 1].contact_type = 'Chat'; break;
            case 6: list[i + 1].contact_type = 'Verification Call'; break;
            case 7: list[i + 1].contact_type = 'Potential Business'; break;
            case 8: list[i + 1].contact_type = 'Validation'; break;
          }
          switch(user_notes[i].contact_result){
            case 1: list[i + 1].contact_result = 'Voicemail'; break;
            case 2: list[i + 1].contact_result = 'No Answer'; break;
            case 3: list[i + 1].contact_result = 'Voicemail Full'; break;
            case 4: list[i + 1].contact_result = 'Answered'; break;
            default: list[i + 1].contact_result = '-'; break;
          }
          list[i + 1].assigned_by = user_notes[i].admin_name?user_notes[i].admin_name:'-';
          list[i + 1].assigned_by_email = user_notes[i].admin_email?user_notes[i].admin_email:'-';
          list[i + 1].assigned_to = user_notes[i].assigned_name?user_notes[i].assigned_name:'-';
          list[i + 1].assigned_to_email = user_notes[i].assigned_email?user_notes[i].assigned_email:'-';
          list[i + 1].notes = user_notes[i].note || user_notes[i].notes  ? user_notes[i].note || user_notes[i].notes :'-';
          list[i + 1].action_value =  user_notes[i].action_value?user_notes[i].action_value:'-';
        }
        if (ext == 0) {
          list.unshift({date_added: 'CSR Notes for '+ this.profile.title+ ' '+ this.profile.agent_name})
          this._shared.exportAsExcelFile(list, 'user_notes', '.xlsx');
        } else if (ext == 1) {
          this._shared.exportAsExcelFile(list, 'user_notes', '.csv');
        }
      break;
      case 6:
        list [0] = {
          created: 'Date Created',
          notes: 'Note',
          action: 'Action',
          admin_name: 'Admin name'
        };
        // tslint:disable-next-line:no-shadowed-variable
        for (let i = 0; i < this.notes.length; i++) {
          if (this.notes[i].action === 'ENROLLMENT') {
            this.notes[i].action = 'Promotional Offer - Enrollment Fee';
          }
          if (this.notes[i].action === 'TRANSACTION') {
            this.notes[i].action = 'Promotional Offer - Transaction Fee';
          }
          this.notes[i].action = this.notes[i].action && this.notes[i].action.replace('<br>', '');
          this.notes[i].notes = this.notes[i].notes && this.notes[i].notes.replace('<br>', '');
          list[i + 1] = {};
          list[i + 1].admin_name = this.notes[i].admin_name ? this.notes[i].admin_name : '-';
          list[i + 1].created = this.notes[i].created ? this.datePipe.transform((this.notes[i].created), 'medium', this.timezone) : '-';
          list[i + 1].notes = this.notes[i].notes ? this.notes[i].notes : '-';
          list[i + 1].action = this.notes[i].action ? this.notes[i].action : '-';
        }
        // tslint:disable-next-line:triple-equals
        if (ext == 0) {
          // list.unshift({date_added: 'Notes List'});
          this._shared.exportAsExcelFile(list, 'Promotional notes', '.xlsx');
        // tslint:disable-next-line:triple-equals
        } else if (ext == 1) {
          this._shared.exportAsExcelFile(list, 'Promotional notes', '.csv');
        }
      break;
      case 7:
        list [0] = {
          created: 'Date Created',
          notes: 'Note',
          action: 'Action',
          admin_name: 'Admin name'
        };
        // tslint:disable-next-line:no-shadowed-variable
        for (let i = 0; i < this.notes.length; i++) {
          this.notes[i].action = this.notes[i].action.replace('<br>', '');
          this.notes[i].note = this.notes[i].note.replace('<br>', '');
          list[i + 1] = {};
          list[i + 1].admin_name = this.notes[i].admin_name ? this.notes[i].admin_name : '-';
          list[i + 1].created = this.notes[i].added_on ? this.datePipe.transform((this.notes[i].added_on), 'medium', this.timezone) : '-';
          list[i + 1].notes = this.notes[i].note ? this.notes[i].note : '-';
          list[i + 1].action = this.notes[i].action ? this.notes[i].action : '-';
        }
        // tslint:disable-next-line:triple-equals
        if (ext == 0) {
          // list.unshift({date_added: 'Notes List'});
          this._shared.exportAsExcelFile(list, 'Subscription notes', '.xlsx');
        // tslint:disable-next-line:triple-equals
        } else if (ext == 1) {
          this._shared.exportAsExcelFile(list, 'Subscription notes', '.csv');
        }
      break;
    }
  }
}
