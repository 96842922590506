import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SharedService, verifyType } from '../shared.service';
import { ApiService } from '../api.service';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { SharedModule } from '../shared.module';
import { ToasterService } from '../toaster/toaster.service';

@Component({
  selector: 'app-verify',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule , NgxMaskDirective, SharedModule],
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
  public data!: extra_data;
  profile:any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter;
  verifyForm!: UntypedFormGroup;
  verifyType = verifyType;
  constructor(
    public bsModalRef: BsModalRef, private _fb: UntypedFormBuilder, public _shared: SharedService, private _api: ApiService, private _toaster: ToasterService
  ) { }

  ngOnInit() {
    if(this.data){
      switch(this.data.verify_type){
        case 'Doctor':
          this.profile = this.data.doctor_profile ? this.data.doctor_profile : {};
          break;
        case 'Patient': 
          this.profile = this.data? JSON.parse(JSON.stringify(this.data)):'';
          console.log(this.profile)
          break;
        case 'Partner': 
          this.profile = this.data? JSON.parse(JSON.stringify(this.data)):'';
          break;
      }
      this.verifyForm = this._fb.group({
        website_verification: [false],
        practice_url: [this.profile.practice_url],
        email_verification: [false],
        doctor_email: [this.profile.doctor_email],
        phone_number_verification: [false],
        doctor_mobile: [this.profile.doctor_mobile],
        doctor_mobile_country_code: [this.profile.doctor_mobile_country_code],
        patient_mobile: [''],
        patient_mobile_country_code: [''],
        partner_mobile: [''],
        partner_mobile_country_code: [''],
        id_verification: [false],
        people_search_verification: [false],
        notes: ['']
      })
    }
  }

  hide() {
    this.bsModalRef.hide()
  }

  cancel() {
    this.passEntry.emit({success: false});
    this.hide();
  }

  handleVerification(){
    if (!this.verifyForm.value.notes || (this.verifyForm.value.notes && !this.verifyForm.value.notes.length)) {
      this._shared.customPopups('Please enter valid note', 2);
      return;
    }
    let params = this.verifyForm.value;

    switch(this.data.verify_type){
      case 'Doctor':
        params['doctor_id'] = this.profile.doctor_id;
        params['action_type'] = 'verify_business';
        if(params.website_verification == 1){         
          if(!this.verifyForm.value.practice_url || this.verifyForm.value.practice_url.trim().length == 0){
            this._shared.customPopups('Please type Business website URL', 2);
            return;
          }
          params['practice_url'] = this.profile.practice_url;
        }
        this._api.api(params, 'business/verify_unverify_doctor')
          .subscribe((data:any)=>{
            if(data.is_error == 1){
              this._shared.customPopups(data.original_text, 2);
              return;
            }else{
              this.hide();
              this.passEntry.emit({success: true});
              this._shared.customPopups('Updated Successfully', 3);
            }
          });
        break;
        case 'Patient':
          delete params.website_verification;
          delete params.email_verification;
          params['patient_id'] = this.profile.patient_id;
          params['patient_mobile_country_code'] = this.profile.patient_mobile_country_code;
          params['action_type'] = 'verify_customer';
          this._api.api(params, 'customer/verify_unverify_patient')
            .subscribe((data:any)=>{
              if(data.is_error == 0){
                this._toaster.showToaster(
                  'success',
                  `Updated successfully!`,
                  'success'
                );
                this.passEntry.emit({success: true});
                this.hide();
              }
            });
          break;
        case 'Partner':
          delete params.website_verification;
          delete params.email_verification;
          params['partner_id'] = this.profile.partner_id;
          params['action_type'] ='verify_partner';
          this._api.api(params, 'verify_unverify_partner')
            .subscribe((data:any)=>{
              if(data.is_error == 1){
                this._shared.customPopups(data.original_text, 2);
                return;
              }else{
                this.passEntry.emit({success: true});
              }
            });
          break;
    }
  }

  onCheckChange(check_id:number){
    switch(check_id){
      case 1:
        this.verifyForm.patchValue({
          practice_url: this.profile.practice_url
        })
        break;
      case 2:
        this.verifyForm.patchValue({
          doctor_email: this.profile.doctor_email
        })
        break;
      case 3:
        this.verifyForm.patchValue({
          doctor_mobile: this.profile.doctor_mobile
        })
        break;
    }
  }

}

interface extra_data {
  doctor_profile?: any,
  verify_type?: 'Doctor' | 'Patient' | 'Partner'
}
